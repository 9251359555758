import React from "react";

function Disruption(props) {

  const positionText = {
    position: 'absolute',
    zIndex: 10,
    padding: '1rem'
  }

  return (
    <div id="disruption-now" className="fix">
      <div className="about-area">
        <div className="about-wrapper">
          <div className="container" style={{ padding: '15px' }}>
            <div className="row row--35 container-area" style={{ paddingTop: '30px' }}>
              <div className="col-lg-1 area-left">
              </div>
              <div className="col-lg-6 area-right align-items-center py-5">
                <h2 className="h2-title title-back-white">The Disruption is now</h2>
                <h4 className="h4-description"><strong>Digital brands are here to stay.</strong></h4>
                <h4 className="h4-description block-mobile" style={{ fontWeight: '300' }}>Atomic Kitchens is here to <strong>lead the way!</strong></h4>
                <h4 className="h4-description" style={{ fontWeight: '300', margin: '1rem auto' }}>We are obsessed with customers and tech. We use big data & trend analysis, digital marketing and training, to develop delivery-only brands that consumers crave, and for you to activate and operate through your Restaurant's kitchen.</h4>  
                <h4 className="h4-description"><strong>Are you ready to increase your current profit 30% to +80%?</strong></h4>
                <h4 className="h4-description" style={{ fontWeight: '300', marginBottom: '2rem' }}>If you are, we are ready to activate our Atomic's brands in your kitchen and give your operation a new and second life!</h4>
	            <h4 className="h4-description title-back-white">We are a 360</h4>
	            <h4 className="h4-description title-back-white">solutions partner</h4>
              </div>
            </div>
            <div className="row row--35 container-footer">
	            <div className="col-lg-7 box-footer align-items-center">
		          	<div className="box-footer-left">
		          		<h4 className="h4-description"><strong>Atomic ></strong> </h4>
		          	</div>
		          	<div className="box-footer-right">
		          		<h4 className="h4-description">// earning capability</h4>
		          		<h4 className="h4-description">// digital brand opportunity</h4>
		          		<h4 className="h4-description">// food-tech expert partner</h4>
		          	</div>
	            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Disruption