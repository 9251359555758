const locale = {
    D1: 'Increase your numbers monthly',
    D2: 'Reduce cost. Get efficient',
    D3: 'Expand Your',
    D4: '_passion',
    D5: '_business',
    S1: 'Do it',
    T1: 'Beyond',
    T2: 'Dark',
    D6: 'Explore new markets, expand your reach & increase your revenue with our Atomic & Co-active model.',
    D7: 'Atomic Kitchens is ready to lead you one step forward into the future.'
};

export default locale;
