const locale = {
    ALERT_1: 'Thanks for your message, we will get back to you shortly!',
    ALERT_2: 'You must fill in the required fields.',
    D1: 'If you are a kitchen owner or operator with extra capacity, looking for efficiency and extra incomes, here we are!',
    D2: 'We will support you from A to Z',
    T1: 'We are.',
    S1: 'Let\'s talk',
    BEYOND: 'Beyond',
    DARK: 'Dark',
    FORM_NAME: 'Your Name *',
    FORM_EMAIL: 'Your Email *',
    FORM_SUBJECT: 'Write a subject *',
    FORM_MESSAGE: 'Your Message *',
    B_SUBMIT: 'Submit',
};

export default locale;