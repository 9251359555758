import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import TabThree from "../elements/tab/TabThree";
import ContactThree from "../elements/contact/ContactThree";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";
import Brand from "../elements/Brand";
import ServiceTwo from "../elements/service/ServiceTwo";
import BeyonDark from '../component/common/BeyondDark';
import Disruption from '../component/common/Disruption';
import Together from '../component/common/Together';
import ExtraIncome from '../component/common/ExtraIncome';
import InfoIcon from '../component/common/InfoIcon';
import TheDisruption from '../component/common/TheDisruption';
import DigitalBrands from '../component/common/DigitalBrands';
import PlatformTastyBoom from '../component/common/PlatformTastyBoom';

import i18n from '../i18n/';
import { useTranslation } from 'react-i18next';
import en from '../i18n/en/portfolioLanding';
import fr from '../i18n/fr/portfolioLanding';
import es from '../i18n/es/portfolioLanding';
i18n.addResourceBundle('en', 'portfolioLanding', en);
i18n.addResourceBundle('fr', 'portfolioLanding', fr);
i18n.addResourceBundle('es', 'portfolioLanding', es);
const SlideList = [
    {
        textPosition: 'text-left',
        category: '',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]

const PortfolioLanding = () => {
  const { t } = useTranslation(['portfolioLanding']);
    let descriptionTeam = 'Atomic Kitchens’ founding team brings deep industry experience and a proven track record in tech, finance, food, marketing and repeatedly scaling companies globally and fast.',
        descriptionTeam1 = 'Atomic Kitchens has led an aggressive and exponential growth strategy in several countries from inception.';

    const PostList = BlogContent.slice(0 , 3);
    return (
        <div className="active-dark">
            <Helmet pageTitle="Atomic Kitchens" />

            <HeaderThree headerClassName="sticky" homeLink="/" logo="symbol-dark" color="color-black"/>
            {/* Start Slider Area   */}
            <div id="home" className="fix">
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide-primary-header slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 container-rotating-text">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            <h1 className="title span-title">{t('T1')}
                                            <TextLoop className="span-title rotating-text">
                                                <span> {t('T2_1')} </span>
                                                <span> {t('T2_2')} </span>
                                            </TextLoop>{" "}
                                            </h1>
                                            <h2 className="span-title">{t('T3')}</h2>
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */} 
            
            {/* NewComponent BeyondDark*/}
                <BeyonDark />
            {/* End NewComponent BeyondDark*/}
            
            {/* Disruption */}
                <Disruption />
            {/* End Disruption */}
            
            {/* Together */}
                <Together />
            {/* End Together */}
            
            {/* Icons */}
                <InfoIcon />
            {/* End Icons */}

            {/* ExtraIncome */}
                <ExtraIncome />
            {/*End ExtraIncome */}

            {/* The Disruption */}
                <TheDisruption />
            {/* End The Disruption */}

            {/* Start Disruption Area */}
                <DigitalBrands />
            {/* End Disruption Area */}

            {/* Start Atomic's First Area */}
            <div id="atomic-brands" className="fix section-first">
                <div className="about-area ptb--50 bg_color--5">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-12">
                                    <div className="row row--35 align-items-center content-text-logos">
	                                    <div className="content-text service-style--3 mb--30 mb_sm--0">
	                                        <h2 className="h2-title" id='brand__title__landing'>{t('T4')}</h2>
	                                        <h2 className="h2-title" id='brand__title__landing'>{t('T5')}</h2>
	                                        <h2 className="h2-title" id='brand__title__landing'>{t('T6')}</h2>
	                                    </div>
	                                    <div className="rn-brand-area">
	                                        <div className="container">
	                                            <Brand />
	                                        </div>
	                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Atomic's First Area */}
            
            {/* Start Info TastyBoom */}
                <PlatformTastyBoom />
            {/* End Info TastyBoom */}

            {/* Start Atomic's First Area */}
            <div id="atomic-brands" className="fix section-first">
                <div className="about-area ptb--50 bg_color--5">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-12">
                                    <div id="become-atomic-partner" className="row row--35 align-items-center content-extra-capacity" style={{ display: 'grid', justifyItems: 'center' }}>
	                                    <div className="col-lg-12 container-text" style={{ paddingBottom: '60px' }}>
	                                        <div className="container" style={{  width: '100%' }}>
                                				<h3 className="title-back-white h3-subtitle title" style={{ marginBottom: '10px' }}>{t('T7')}</h3>
	                                        </div>
	                                    </div>
                                        <div className="col-lg-12 container-beyond">
							                <div className='text-center beyond'>
							                  <div className='dark'>
							                    <h3 className="title-dark" style={{ fontWeight: 400 }}>{t('T8')}</h3>
							                    <h3 className='title-zoom'>{t('T9')}</h3>
							                  </div>
							                </div>
                                            <div className="beyond-description">
                                                <h4 style={{ fontWeight: 400 }} className='h4-description py-2'>{t('D1')}</h4>

                                                <h4 style={{ fontWeight: 400 }} className='h4-description py-2'>{t('D2')}</h4>

                                                <h4 style={{ fontWeight: 400 }} className='h4-description py-2'>{t('D3')}</h4>

                                                <h4 style={{ fontWeight: 400 }} className='h4-description py-2'>{t('D4')}</h4>

                                                <h4 style={{ fontWeight: 400 }} className='h4-description py-2'>{t('D5')}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Atomic's First Area */}

            {/* Start Contact Area */}
            <div id="contact" className="fix">
                <div className="about-area">
                    <div className="about-wrapper" style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
                        <div className="container">
			                <div className="rn-contact-area ptb--60">
			                    <ContactThree contactImages="/assets/images/about/contact_image_atomic.png" contactTitle={t('CT')} />
			                </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Contact Area */}

            <Footer footerCustomClass="bg_color--5" />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
        </div>
    )
}

export default PortfolioLanding;
