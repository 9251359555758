const locale = {
    TABS_T_1: "Co-active model",
    TABS_D_1: "<p class='h4-description'>At Atomic, starting from tech to product, we go <span style='font-weight:600'>Beyond</span>Dark kitchens implementing a co-active model in which we deploy our digital brands through our own & third party kitchens.</p><p class='h4-description'>We support restaurateurs from A to Z, making them more efficient, profitable and sustainable, contributing to the local development of the communities where they operate, and to the global industry through our Co-active strategies.</p><p class='h4-description'>Are we a ghost/dark kitchen? <br/> No. We are <span style='font-weight:600'>Beyond</span>Dark.</p>",
    TABS_T_2: "Capital-Efficient",
    TABS_D_2: "<p class='h4-description'>Shared infrastructure for production and distribution by multiple restaurants to focus solely on takeout and delivery.</p><p class='h4-description'>Increase your numbers monthly with only a nominal increase in overhead and reduce your costs through centralized purchasing and lower platform fees.</p><p class='h4-description'>0% commission on our own e-commerce platform.</p><p class='h4-description'>Save on global training, aggregators, technical support, delivery drivers, food packaging and online marketing campaigns.</p>",
    TABS_T_3: "Digital Brands",
    TABS_D_3: "<p class='h4-description'>Atomic Kitchens creates unbeatable concepts and value-based brands for e-consumers, quickly getting the right positioning on both, market and app’s with a flawless reputation and high satisfaction scores in the markets where they operate.</p><p class='h4-description'>The brands and menus developed by Atomic Kitchens are created based on market research and data analysis. They are easy to implement and operate, giving its partners what they are looking for:<br/><ul class='ul-text'><li>// Maximize incremental sales and drive better margins with affordable, low-risk expansion opportunities.</li><li>// Operate profitable and high-value brands for end users.</li></ul></p>",
    TABS_T_4: "Own e-commerce platform",
    TABS_D_4: "<p class='h4-description'>Atomic Kitchens has its own e-commerce platform. An effective and personalized state-of-the-art online ordering system designed by experts in user experience (UX/UI) and last mile strategic alliances with exceptional support.</p><p class='h4-description'>Own Digital Brands websites and apps with the newest technology to process online orders directly.</p><p class='h4-description'>One universal menu to easily manage all your online menus</p><p class='h4-description'>The data and tools you need to truly understand your business</p>",
    TABS_T_5: "Digital Marketing",
    TABS_D_5: "<p class='h4-description'>Powered by The Atomic Lab</p><p class='h4-description'>Marketing strategies built for digital takeout multi-brands and products.</p><p class='h4-description'>A team of experts in digital marketing, advertising, search marketing and social media creates tailor made strategies for each brand and kitchens, using tech & research, also creates creative content, digital advertising and Smart promotions on platforms and apps.</p>",
};

export default locale;