import React, { Component } from "react";

class Brand extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="brand-style-2">
                    <div className="row mt--60 mt_sm--40" style={{marginRight: 15}}> 
                        {/*<div className="col-lg-4 col-6 mb--30">
                            <a href="" target="_blank" rel="noopener noreferrer">
                                <img src="/assets/images/brand/itacate.png" alt="Logo Images"/>
                            </a>
                        </div>  
                        <div className="col-lg-4 col-6 mb--30" >
                            <a href="" target="_blank" rel="noopener noreferrer">
                                <img src="/assets/images/brand/noahs-kitchens.png" alt="Logo Images"/>
                            </a>
                        </div>  
                        <div className="col-lg-4 col-6 mb--30" >
                            <a href="" target="_blank" rel="noopener noreferrer">
                                <img src="/assets/images/brand/poked.png" alt="Logo Images"/>
                            </a>
                        </div>  
                        <div className="col-lg-4 col-6 mb--30" >
                            <a href="" target="_blank" rel="noopener noreferrer">
                                <img src="/assets/images/brand/viva-la-mila.png" alt="Logo Images"/>
                            </a>
                        </div>  
                        <div className="col-lg-4 col-6 mb--30" >
                            <a href="" target="_blank" rel="noopener noreferrer">
                                <img src="/assets/images/brand/wing-it.png" alt="Logo Images"/>
                            </a>
                        </div> */}
                        <div className="col-lg-4 col-6 mb--30">
                            <img src="/assets/images/brand/itacate.png" alt="Logo Images"/>
                        </div> 
                        <div className="col-lg-4 col-6 mb--30">
                            <img src="/assets/images/brand/noahs-kitchens.png" alt="Logo Images"/>
                        </div> 
                        <div className="col-lg-4 col-6 mb--30">
                            <img src="/assets/images/brand/poked.png" alt="Logo Images"/>
                        </div> 
                        <div className="col-lg-4 col-6 mb--30">
                            <img src="/assets/images/brand/viva-la-mila.png" alt="Logo Images"/>
                        </div> 
                        <div className="col-lg-4 col-6 mb--30">
                            <img src="/assets/images/brand/wing-it.png" alt="Logo Images"/>
                        </div>  
                        <div className="col-lg-4 col-6 mb--30">
                            <img src="/assets/images/brand/vittore.png" alt="Logo Images"/>
                        </div> 
                        {/* <div className="col-lg-4 col-6 mb--30" >
                            <img src="/assets/images/brand/futusushi-ak.png" alt="Logo Images"/>
                        </div> 
                        <div className="col-lg-4 col-6 mb--30" >
                            <img src="/assets/images/brand/asan-sushi-ak.png" alt="Logo Images"/>
                        </div>
                        <div className="col-lg-4 col-6 mb--30" >
                            <img src="/assets/images/brand/trending-ak.png" alt="Logo Images"/>
                        </div>  
                        <div className="col-lg-4 col-6 mb--30" >
                            <img src="/assets/images/brand/vittore.png" alt="Logo Images"/>
                        </div> */}
                        {/* <div className="col-lg-4 col-6 mb--30" >
                            <img src="/assets/images/brand/wokadely-ak.png" alt="Logo Images"/>
                        </div> 
                        <div className="col-lg-4 col-6 mb--30" >
                            <img src="/assets/images/brand/walk-2-ak.png" alt="Logo Images"/>
                        </div> 
                        <div className="col-lg-4 col-6 mb--30" >
                            <img src="/assets/images/brand/salad-ba-ak.png" alt="Logo Images"/>
                        </div> 
                        <div className="col-lg-4 col-6 mb--30" >
                            <img src="/assets/images/brand/wakai-ak.png" alt="Logo Images"/>
                        </div>  
                        <div className="col-lg-4 col-6 mb--30" >
                            <img src="/assets/images/brand/salad-box-ak.png" alt="Logo Images"/>
                        </div> */}
                        {/* <div className="col-lg-4 col-6 mb--30" >
                            <img src="/assets/images/brand/house-salmon-ak.png" alt="Logo Images"/>
                        </div> 
                        <div className="col-lg-4 col-6 mb--30" >
                            <img src="/assets/images/brand/club-poke-ak.png" alt="Logo Images"/>
                        </div> 
                        <div className="col-lg-4 col-6 mb--30" >
                            <img src="/assets/images/brand/be-poke-ak.png" alt="Logo Images"/>
                        </div>
                        <div className="col-lg-4 col-6 mb--30" >
                            <img src="/assets/images/brand/poke-ak.png" alt="Logo Images"/>
                        </div>
                        <div className="col-lg-4 col-6 mb--30" >
                            <img src="/assets/images/brand/brand-12.png" alt="Logo Images"/>
                        </div>*/}
                        {/* <div className="col-lg-4 col-6 mb--30" >
                            <img src="/assets/images/brand/hawai-ak.png" alt="Logo Images"/>
                        </div>
                        <div className="col-lg-4 col-6 mb--30" >
                            <img src="/assets/images/brand/inkatex-ak.png" alt="Logo Images"/>
                        </div>
                        <div className="col-lg-4 col-6 mb--30" >
                            <img src="/assets/images/brand/wraps-ak.png" alt="Logo Images"/>
                        </div> */}
                    </div> 
                </div>
            </React.Fragment>
        )
    }
}
export default Brand;