import React from "react";

import i18n from '../../i18n/';
import { useTranslation } from 'react-i18next';
import en from '../../i18n/en/disruption';
import fr from '../../i18n/fr/disruption';
import es from '../../i18n/es/disruption';
i18n.addResourceBundle('en', 'disruption', en);
i18n.addResourceBundle('fr', 'disruption', fr);
i18n.addResourceBundle('es', 'disruption', es);

function Disruption(props) {

  const positionText = {
    position: 'absolute',
    zIndex: 10,
    padding: '1rem'
  }

  const { t } = useTranslation(['disruption']);

  return (
    <div id="disruption-now" className="fix">
      <div className="about-area">
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 container-area">
              <div className="col-lg-6 area-left">
                <div className="thumbnail-paralax">
	                <div style={positionText} className="w-50 float-box">
	                  <h4 className="h4-description" style={{ textShadow: '0px 1px 5px #000' }}><strong>{t('D1_1')}</strong> {t('D1_2')}</h4>
	                  <h4 className="h4-description" style={{ textShadow: '0px 1px 5px #000' }}>{t('D2')}</h4>
	                  <h4 className="h4-description" style={{ textShadow: '0px 1px 5px #000' }}>{t('D3')}</h4>
	                  <h4 className="h4-description" style={{ color: '#ff0066', textShadow: '0px 1px 3px rgb(4, 4, 4)' }}><strong>{t('D4_1')}</strong> {t('D4_2')}</h4>
	                  <h4 className="h4-description" style={{ color: '#ff0066', textShadow: '0px 1px 3px rgb(4, 4, 4)' }}>{t('D5')}</h4>
	                </div>
	                <div style={positionText} className="w-50 float-box-bottom">
	                  <h4 className="h4-description title-back-white">{t('D6')}</h4>
	                  <h4 className="h4-description title-back-white">{t('D7')}</h4>
	                </div>
                </div>
              </div>
              <div className="col-lg-6 area-right align-items-center py-5">
                <h2 className="h2-title title-back-white">{t('T1')}</h2>
                <h4 className="h4-description"><strong>{t('D8')}</strong></h4>
                <h4 className="h4-description block-mobile" style={{ fontWeight: '300' }}>{t('D9_1')} <strong>{t('D9_2')}</strong></h4>
                <h4 className="h4-description" style={{ fontWeight: '300', margin: '1rem auto' }}>{t('D10')}</h4>
                <h4 className="h4-description"><strong>{t('D11')}</strong></h4>
                <h4 className="h4-description" style={{ fontWeight: '300', marginBottom: '2rem' }}>{t('S1')}</h4>
	            <h4 className="h4-description title-back-white">{t('D6')}</h4>
	            <h4 className="h4-description title-back-white">{t('D7')}</h4>
              </div>
            </div>
            <div className="row row--35 container-footer">
	            <div className="col-lg-7 box-footer align-items-center">
		          	<div className="box-footer-left">
		          		<h4 className="h4-description"><strong>Atomic</strong> </h4>
		          	</div>
		          	<div className="box-footer-right">
		          		<h4 className="h4-description">// {t('D13')}</h4>
		          		<h4 className="h4-description">// {t('D14')}</h4>
		          		<h4 className="h4-description">// {t('D15')}</h4>
		          	</div>
	            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Disruption