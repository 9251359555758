import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import i18n from "../../i18n/";
import { withTranslation } from "react-i18next";
import en from "../../i18n/en/tabTwo";
import fr from "../../i18n/fr/tabTwo";
import es from "../../i18n/es/tabTwo";
i18n.addResourceBundle("en", "tabTwo", en);
i18n.addResourceBundle("fr", "tabTwo", fr);
i18n.addResourceBundle("es", "tabTwo", es);

class TabsTwo extends Component {
  render() {
    const singleTab = {
      padding: "2px 15px 0",
      position: "relative",
      border: "1px solid #fff",
    };
    const titleTab = {
      position: "absolute",
      top: "-15px",
      left: "8px",
      fontWeight: "400",
      color: "#fff",
      width: "max-content",
      padding: "0px 5px",
      backgroundColor: "#FC0266",
    };

    const tabs = [
      {
        title: this.props.t("TABS_T_1"),
        description: this.props.t("TABS_D_1"),
      },
      {
        title: this.props.t("TABS_T_2"),
        description: this.props.t("TABS_D_2"),
      },
      {
        title: this.props.t("TABS_T_3"),
        description: this.props.t("TABS_D_3"),
      },
      {
        title: this.props.t("TABS_T_4"),
        description: this.props.t("TABS_D_4"),
      },
      {
        title: this.props.t("TABS_T_5"),
        description: this.props.t("TABS_D_5"),
      },
    ];
    const { tabStyle } = this.props;
    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    {tabs.map((t, key) => (
                      <Tab className="h4-description" key={key}>
                        // {t.title}
                      </Tab>
                    ))}
                  </TabList>

                  {tabs.map((t, key) => (
                    <TabPanel key={key}>
                      <div style={singleTab} className="single-tab-content">
                        <h3 className="h3-subtitle" style={titleTab}>
                          // {t.title}
                        </h3>
                        <div
                          dangerouslySetInnerHTML={{ __html: t.description }}
                        />
                      </div>
                    </TabPanel>
                  ))}
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    );
  }
}

export default withTranslation(["tabTwo"])(TabsTwo);
