import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import { BsDownload } from 'react-icons/bs'


const Latam = () => {

    return (
        <div className="active-dark">
            <Helmet pageTitle="Atomic Kitchens" />
                        
            {/* Start Contact Area */}
            <div id="latam" className="fix">
                <div className="about-area">
                    <div className="pdf-des about-wrapper" style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
                        <div className="container">
			                <iframe src="/assets/pdf/BROSHUREchile.pdf" width="100%" style={{ height: '99vh' }}>
    						</iframe>
                        </div>
                    </div>
                    <div className="pdf-mob about-wrapper" style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
                        <div class="iconDownload">

                            <a href="/assets/pdf/Atomic_Partner.pdf">
                                <BsDownload style={{ width: '25px', height: '25px' }}/>
                            </a>

                        </div>

                        <div className="container flyer">
			                <img src="/assets/images/blog/Atomic-Partner.jpg">
    						</img>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Contact Area */}

            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
        </div>
    )
}

export default Latam;
