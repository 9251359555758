import React from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";

import i18n from '../i18n/';
import { useTranslation } from 'react-i18next';
import en from '../i18n/en/newsAndInfo';
import fr from '../i18n/fr/newsAndInfo';
import es from '../i18n/es/newsAndInfo';
i18n.addResourceBundle('en', 'newsAndInfo', en);
i18n.addResourceBundle('fr', 'newsAndInfo', fr);
i18n.addResourceBundle('es', 'newsAndInfo', es);

const SlideList = [
  {
    textPosition: "text-left",
    category: "",
    description: "",
    buttonText: "",
    buttonLink: "",
  },
];

const DisruptionLanding = () => {

  const { t } = useTranslation(['newsAndInfo']);


  const style = {
    backgroundColor: "#ff0066",
    paddingBottom: '0'
  };

  const titleBlock = {
    backgroundColor: "#ff0066",
    paddingBottom: 20,
    paddingTop: 20,
  };


  return (
    <div className="active-dark">
      <Helmet pageTitle="Atomic Kitchens" />

      <HeaderThree
        headerClassName="sticky"
        homeLink="/"
        logo="symbol-dark"
        color="color-black"
      />
      {/* Start Slider Area   */}
      <div id="news" className="fix">
        <div className="slider-wrapper" style={{ padding: '67px 0' }}>
        </div>
      </div>
      {/* End Slider Area   */}

      <div id="in-news" className="fix">
        <div className="about-area" style={{ backgroundColor: "#ff0066", padding: '40px 0 0' }}>
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center container-area">
                <div className="col-lg-12 area-right">
                  <h2 className="h2-title title" style={{ marginBottom: '20px' }}>{t('T7')}</h2>
                  <h4 className="h4-description" style={{ fontWeight: '400', marginBottom: '20px' }}>
                    <strong>{t('D21_1')}</strong> {t('D21_2')}
                  </h4>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{backgroundColor: 'white', padding: 10}}></div>

            {/* noticia  */}
      <div id="startup-arg" className="fix">
        <div className="about-area" style={titleBlock}>
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center container-area">
                <div className="col-lg-12 area-right" style={{display: 'flex', justifyContent: 'center'}}>
                  <h4 className="h4-description" style={{ fontWeight: '400', width: 'max-content' }}>{/* , maxWidth: '80%' */}
                  <strong>El Mercurio:</strong> Atomic Kitchens adquiere <a style={{ color: "white", textDecoration: 'underline #fff' }} target="_blank" href="https://lnkd.in/djpGdCGE?trk=public_post_share-update_update-text">startup argentina</a> y prepara ronda para crecer en el exterior.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="startup-arg-img"
        className="fix"
        style={{ backgroundColor: "white" }}
      >
        <div className="about-wrapper about-wrapper-img ptb--80">
          <div className="container">
            <div className="row row--35 align-items-center container-area">
              <div className="col-lg-12 area-right">
                <div className="thumbnail" style={{textAlign: 'center'}}>
                    <a target="_blank" href="https://lnkd.in/djpGdCGE?trk=public_post_share-update_update-text">
                      <img
                        style={{ opacity: "0.9", maxWidth: 670 }}
                        className="w-100"
                        src="/assets/images/blog/startup-arg.png"
                        alt="Brand"
                      />
                    </a>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      {/* end noticia  */}


      {/* noticia  */}
      <div id="bigdata" className="fix">
        <div className="about-area" style={titleBlock}>
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center container-area">
                <div className="col-lg-12 area-right" style={{display: 'flex', justifyContent: 'center'}}>
                  <h4 className="h4-description" style={{ fontWeight: '400', width: 'max-content' }}>{/* , maxWidth: '80%' */}
                  <strong>LUN | Revista Mercado Mayorista:</strong> Esta oferta se mueve entre <a style={{ color: "white", textDecoration: 'underline #fff' }} target="_blank" href="https://www.lun.com/Pages/NewsDetail.aspx?dt=2022-03-14&EsAviso=0&PaginaId=22&SupplementId=3&bodyid=0">big data y delivery</a>.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="new-bigdata-img"
        className="fix"
        style={{ backgroundColor: "white" }}
      >
        <div className="about-wrapper about-wrapper-img ptb--80">
          <div className="container">
            <div className="row row--35 align-items-center container-area">
              <div className="col-lg-12 area-right">
                <div className="thumbnail" style={{textAlign: 'center'}}>
                    <a target="_blank" href="https://www.lun.com/Pages/NewsDetail.aspx?dt=2022-03-14&EsAviso=0&PaginaId=22&SupplementId=3&bodyid=0">
                      <img
                        style={{ opacity: "0.9", maxWidth: 670 }}
                        className="w-100"
                        src="/assets/images/blog/big-data.png"
                        alt="Brand"
                      />
                    </a>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      {/* end noticia  */}




      {/* noticia  */}
      <div id="box-startup" className="fix">
        <div className="about-area" style={titleBlock}>
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center container-area">
                <div className="col-lg-12 area-right" style={{display: 'flex', justifyContent: 'center'}}>
                  <h4 className="h4-description" style={{ fontWeight: '400', width: 'max-content' }}>{/* , maxWidth: '80%' */}
                  <strong>{t('D30_1')}</strong> {t('D30_2')} <a style={{ color: "white", textDecoration: 'underline #fff' }} target="_blank" href="https://pyme.emol.com/26515/atomic-kitchens-restaurantes-aumentan-cerca-del-55-de-sus-ventas-gracias-a-esta-startup/">{t('D30_3')}</a>.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="new-paper-startup-img"
        className="fix"
        style={{ backgroundColor: "white" }}
      >
        <div className="about-wrapper about-wrapper-img ptb--80">
          <div className="container">
            <div className="row row--35 align-items-center container-area">
              <div className="col-lg-12 area-right">
                <div className="thumbnail" style={{textAlign: 'center'}}>
                    <a target="_blank" href="https://pyme.emol.com/26515/atomic-kitchens-restaurantes-aumentan-cerca-del-55-de-sus-ventas-gracias-a-esta-startup/">
                      <img
                        style={{ opacity: "0.9", maxWidth: 670 }}
                        className="w-100"
                        src="/assets/images/blog/image43.png"
                        alt="Brand"
                      />
                    </a>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
{/* end noticia  */}

{/* noticia  */}
      <div id="box-fischer" className="fix">
        <div className="about-area" style={titleBlock}>
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center container-area">
                <div className="col-lg-12 area-right" style={{display: 'flex', justifyContent: 'center'}}>
                  <h4 className="h4-description" style={{ fontWeight: '400', width: 'max-content' }}>{/* , maxWidth: '80%' */}
                  <strong>{t('D23_1')}</strong> {t('D23_2')} <a style={{ color: "white", textDecoration: 'underline #fff' }} target="_blank" href="https://www.df.cl/noticias/empresas/industria/atomic-kitchens-la-nueva-apuesta-de-humberto-fischer-en-el-negocio-de/2021-12-31/130353.html">{t('D23_3')}</a>.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="new-paper-img"
        className="fix"
        style={{ backgroundColor: "white" }}
      >
	      <div className="about-wrapper about-wrapper-img ptb--80">
	        <div className="container">
	          <div className="row row--35 align-items-center container-area">
	            <div className="col-lg-12 area-right">
	              <div className="thumbnail" style={{textAlign: 'center'}}>
	                  <a target="_blank" href="https://www.df.cl/noticias/empresas/industria/atomic-kitchens-la-nueva-apuesta-de-humberto-fischer-en-el-negocio-de/2021-12-31/130353.html">
		                  <img
		                    style={{ opacity: "0.9", maxWidth: 670 }}
		                    className="w-100"
		                    src="/assets/images/blog/image42.jpg"
		                    alt="Brand"
		                  />
	                  </a>
	                </div>
	              </div>
	          </div>
	        </div>
	      </div>
      </div>
{/* end noticia  */}

{/* noticia  */}
      <div id="radioagricultura" className="fix">
        <div className="" style={titleBlock}>
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center container-area">
                <div className="col-lg-12 area-right">
                  <h4 className="h4-description" style={{ fontWeight: '400', paddingTop: '0px' }}>
                  	<strong>{t('D24_1')}</strong> <a 
                  		style={{ color: "white", textDecoration: 'underline #fff' }}
                  		target="_blank" 
                  		href="https://www.radioagricultura.cl/economia/2022/01/30/startup-del-sector-food-tech-y-dark-kitchens-llega-al-mercado-norteamericano.html"
                  		>{t('D24_2')}</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="new-paper-imgs"
        className="fix"
        style={{ backgroundColor: "white" }}
      >
      <div className="about-wrapper about-wrapper-img ptb--80">
        <div className="container">
          <div className="row row--35 align-items-center container-area">
            <div className="col-lg-12 area-right">
              <div className="thumbnail" style={{textAlign: 'center'}}>
              	<a 
              		target="_blank" 
              		href="https://www.radioagricultura.cl/economia/2022/01/30/startup-del-sector-food-tech-y-dark-kitchens-llega-al-mercado-norteamericano.html"
              		>
                  <img
                    style={{ opacity: "0.9", maxWidth: 670 }}
                    className="w-100"
                    src="/assets/images/blog/image32.png"
                    alt="Brand"
                  />
                  </a>
                </div>
              </div>
          </div>
        </div>
      </div>
      </div>
{/* end noticia  */}

{/* noticia  */}
      <div id="e-negocios" className="fix">
        <div className="" style={titleBlock}>
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center container-area">
                <div className="col-lg-12 area-right">
                  <h4 className="h4-description" style={{ fontWeight: '400', paddingTop: '0px' }}>
                  	<strong>{t('D25_1')}</strong> <a 
                  		style={{ color: "white", textDecoration: 'underline #fff' }}
                  		target="_blank" 
                  		href="https://www.e-negocios.cl/2022/02/09/startup-del-sector-food-tech-y-dark-kitchens-llega-al-mercado-norteamericano/"
                  		>{t('D25_2')}</a>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="new-paper-imgss"
        className="fix"
        style={{ backgroundColor: "white" }}
      >
	      <div className="about-wrapper about-wrapper-img ptb--80">
	        <div className="container">
	          <div className="row row--35 align-items-center container-area">
	            <div className="col-lg-12 area-right">
	              <div className="thumbnail" style={{textAlign: 'center'}}>
	              	<a 
	              		target="_blank" 
	              		href=""
	              		>
	                   <img
	                    style={{ opacity: "0.9", maxWidth: 670 }}
	                    className="w-100"
	                    src="/assets/images/blog/image33.png"
	                    alt="Brand"
	                  />
	                  </a>
	                </div>
	              </div>
	          </div>
	        </div>
	      </div>
      </div>
{/* end noticia  */}

{/* noticia  */}
      <div id="thetimes" className="fix">
        <div className="about-area" style={titleBlock}>
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center container-area">
                <div className="col-lg-12 area-right" style={{display: 'flex', justifyContent: 'center'}}>
                  <h4 className="h4-description" style={{ fontWeight: '400', width: 'max-content' }}>{/* , maxWidth: '80%' */}
                  <strong>{t('D26_1')}</strong> <a style={{ color: "white", textDecoration: 'underline #fff' }} target="_blank" href="https://thetimes.cl/contenido/3252/restauranteros-aumentan-cerca-del-55-de-sus-ventas-gracias-a-la-food-tech-chilen"
                  >{t('D26_2')}</a>.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="new-paper-imgsss"
        className="fix"
        style={{ backgroundColor: "white" }}
      >
	      <div className="about-wrapper about-wrapper-img ptb--80">
	        <div className="container">
	          <div className="row row--35 align-items-center container-area">
	            <div className="col-lg-12 area-right">
	              <div className="thumbnail" style={{textAlign: 'center'}}>
	                  <a target="_blank" href="https://thetimes.cl/contenido/3252/restauranteros-aumentan-cerca-del-55-de-sus-ventas-gracias-a-la-food-tech-chilen">
		                  <img
		                    style={{ opacity: "0.9", maxWidth: 670 }}
		                    className="w-100"
		                    src="/assets/images/blog/diario.png"
		                    alt="Brand"
		                  />
	                  </a>
	                </div>
	              </div>
	          </div>
	        </div>
	      </div>
      </div>
{/* end noticia  */}

{/* noticia  *
      <div id="work-cafe" className="fix">
        <div className="about-area" style={titleBlock}>
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center container-area">
                <div className="col-lg-12 area-right" style={{display: 'flex', justifyContent: 'center'}}>
                  <h4 className="h4-description" style={{ fontWeight: '400', width: 'max-content' }}>{/* , maxWidth: '80%' 
                  <strong>{t('D27_1')}</strong> <a style={{ color: "white", textDecoration: 'underline #fff' }} target="_blank" href="https://rudo.video/vod/yfdSnJAVRB4"
                  >{t('D27_2')}</a>. {t('D27_3')}
                  <h4 className="h4-description" style={{ fontWeight: '400', width: 'max-content' }}>
                  <strong>Radio Infinita:</strong> <a style={{ color: "white", textDecoration: 'underline #fff' }} target="_blank" href="https://rudo.video/vod/yfdSnJAVRB4"
                  >Work Café Santander. Atomic Kitchens CEO interview</a>. Starting at: 30:30 minutes.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="video-work-cafe"
        className="fix"
        style={{ backgroundColor: "white" }}
      >
	      <div className="about-wrapper about-wrapper-img">
	        <div className="container">
	          <div className="row row--35 align-items-center container-area">
	            <div className="col-lg-12 area-right">
	              <div className="thumbnail" style={{ textAlign: 'center', padding: '20px 40px'}}>
	                  <a target="_blank" href="https://rudo.video/vod/yfdSnJAVRB4">
		                  <img
		                    style={{ opacity: "0.9", maxWidth: 670, borderRadius: '0' }}
		                    className="w-100"
		                    src="/assets/images/blog/video.jpg"
		                    alt="Brand"
		                  />
	                  </a>
	                </div>
	              </div>
	          </div>
	        </div>
	    {/*
	        <div className="container">
	          <div className="row row--35 align-items-center container-area">
	            <div className="col-lg-12 area-right">
	              <div className="video">
	                <div className="container-video">
	                  <iframe
	                    class="responsive-iframe"
	                    src="https://rudo.video/vod/yfdSnJAVRB4"
	                    width="100%"
	                    height="338"
	                    allowscriptaccess="always"
	                    allowfullscreen="true"
	                    webkitallowfullscreen="true"
	                    frameborder="0"
	                    scrolling="no"
	                    allow="fullscreen"
	                  ></iframe>
	                  <video 
	                    class="responsive-iframe"
	                  	src="https://rudo.video/vod/yfdSnJAVRB4" 
	                  	autoplay 
	                  	controls
	                    width="100%"
	                    height="338"
	                  ></video>
	                </div>
	              </div>
	            </div>
	          </div>
	        </div>
	   *
	      </div>
      </div>
{/* end noticia  */}


      <div id="more-info" className="fix">
        <div className="about-area" style={titleBlock}>
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center container-area">
                <div className="col-lg-12 area-right" >
                  <h4 className="h4-description" style={{ fontWeight: '400', width: 'max-content' }}>{/* , maxWidth: '80%' */}
                  <strong>{t('D28')}</strong>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="disrupted-world"
        className="fix"
        style={{ backgroundColor: "white" }}
      >
        <div className="ptb--40">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center container-area">
                <div className="col-lg-12 area-right info">
                  <h4 className="h4-description">
                    {t('D29_1')}
                    <a 
                      target="_blank"
                      style={{ paddingLeft: '5px', color: "#ff0066", textDecoration: 'underline #ff0066' }}
                      href="https://www.linkedin.com/pulse/atomic-importance-employee-happiness-atomic-kitchens/?trackingId=l%2B%2Fozy6pIBk86Kvwl4AxLw%3D%3D">
                    {t('D29_2')}</a>.</h4>
                  <h4 className="h4-description">
                    {t('D29_3')} 
                    <a 
                      target="_blank"
                      style={{ paddingLeft: '5px', color: "#ff0066", textDecoration: 'underline #ff0066' }}
                      href="https://www.linkedin.com/pulse/customer-obsession-vs-focus-marketing-challenges-satisfying-/?trackingId=jTsHNrYYkO7mm5KVHs5hgw%3D%3D">
                      {t('D29_4')}</a>.</h4>
                  <h4 className="h4-description">
                    {t('D29_5')} 
                    <a 
                      target="_blank"
                      style={{ paddingLeft: '5px', color: "#ff0066", textDecoration: 'underline #ff0066' }}
                      href="https://www.linkedin.com/pulse/atomic-kitchens-un-actor-global-en-la-disrupci%25C3%25B3n-de-restaurantes-/?trackingId=nEyV4Htjz9HZYjjycl85Qw%3D%3D">
                     {t('D29_6')}</a>.</h4>
                  <h4 className="h4-description">
                    {t('D29_7')} 
                    <a 
                      target="_blank"
                      style={{ paddingLeft: '5px', color: "#ff0066", textDecoration: 'underline #ff0066' }}
                      href="https://www.linkedin.com/company/atomic-kitchens/posts/?feedView=all&viewAsMember=true">
                      {t('D29_8')}</a>.</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer footerCustomClass="bg_color--5" />
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

export default DisruptionLanding;
