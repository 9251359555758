import React, { Component } from "react";
import { FaGlobe, FaChartLine, FaLanguage } from "react-icons/fa";
import { GiShakingHands, GiTakeMyMoney } from "react-icons/gi";
import { GrContactInfo } from "react-icons/gr";

import LangMenu from "./LangMenu";

import i18n from "../i18n";
import { withTranslation } from "react-i18next";
import NavDropdown from "react-bootstrap/NavDropdown";
import en from "../i18n/en/linkMenu";
import fr from "../i18n/fr/linkMenu";
import es from "../i18n/es/linkMenu";
i18n.addResourceBundle("en", "linkMenu", en);
i18n.addResourceBundle("fr", "linkMenu", fr);
i18n.addResourceBundle("es", "linkMenu", es);

function anclaClick(idTag) {
  let divTop = document.querySelector("." + idTag).getAttribute("data-href");
  document.querySelector(".header-wrapper").classList.remove("menu-open");

  window.location.href = `/#${divTop}`;
  /*
    if (window.location.pathname !== '/') {
    	window.location.href = `/#${divTop}`
    }
    else {
    	let top = document.querySelector(`#${divTop}`).getBoundingClientRect().top
    	window.scrollTo({top: top - 70, left: 0, behavior: 'smooth' })
    }
    */
}

class LinkMenu extends Component {
  constructor(props) {
    super(props);
    this.optionTrigger = this.optionTrigger.bind(this);
  }
  optionTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");

    anclaClick("header-option-1");
  }

  option2Trigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");

    anclaClick("header-option-2");
  }

  render() {
    const { fromHome } = this.props;
    let routePrefix = fromHome ? "/#" : "#";
    return (
      <>
        <li
          className="li-menu li-logo"
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "55px 0",
          }}
        >
          <a
            className="header-option-1"
            onClick={this.optionTrigger}
            href="javascript:void(0);"
            data-href="home"
          >
            <img
              src="/assets/images/logo/new_Atomic_Kitchens_Logo_Header.png"
              alt="Atomic_Kitchens"
            />
          </a>
        </li>
        <li className="li-menu">
          <a
            className="header-option-2"
            onClick={this.option2Trigger}
            href="javascript:void(0);"
            data-href="become-atomic-partner"
          >
            <svg
              className="svg"
              style={{ marginBottom: "4px", marginRight: "4px" }}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 149.38 139.43"
            >
              <g id="Layer_2" data-name="Layer 2">
                <g id="VECTOR">
                  <path
                    className="Graphic-Style-7"
                    d="M84.23,84.06l44,44c4.9,4.94-2.93,12.69-7.83,7.75L76.87,92.39"
                  />
                  <path
                    className="Graphic-Style-7"
                    d="M58.19,73.61c-3.86-2.11-10.13-.07-16.12-5.18L7.32,34.27c-10.65-10.66-2-29.13-2-29.13l60.6,60.6"
                  />
                  <path
                    className="Graphic-Style-7"
                    d="M138.78,41.3c-8.59,8.59-20.73,10.88-29.4,6.23L21.06,135.84c-4.89,5-12.72-2.8-7.82-7.74l88.44-88.44C97.26,31,99.6,19.07,108.08,10.6c10.15-10.16,25.26-11.51,33.73-3S148.94,31.15,138.78,41.3Z"
                  />
                </g>
              </g>
            </svg>
            {this.props.t("L1")}
          </a>
        </li>
        {/*<li className="li-menu">
          <a href="/news">
            <FaGlobe style={{ marginBottom: "4px", marginRight: "4px" }} />
            {this.props.t("L2")}
          </a>
        </li>*/}
        <li className="li-menu">
          <a href="/corporate-info">
            <GrContactInfo
              className="svg"
              style={{ marginBottom: "4px", marginRight: "4px" }}
            />
            {this.props.t("L3")}
          </a>
        </li>
        {/*<li className="li-menu">
          <a href={`/investors`}>
            <FaChartLine style={{ marginBottom: "4px", marginRight: "4px" }} />
            {this.props.t("L4")}
          </a>
      </li> */}
        <li className="li-menu li-dropdown-lang">
          <LangMenu></LangMenu>
        </li>
      </>
    );
  }
}
export default withTranslation(["linkMenu"])(LinkMenu);
