import React from "react";

import i18n from '../../i18n/';
import { useTranslation } from 'react-i18next';
import en from '../../i18n/en/digitalBrands';
import fr from '../../i18n/fr/digitalBrands';
import es from '../../i18n/es/digitalBrands';
i18n.addResourceBundle('en', 'digitalBrands', en);
i18n.addResourceBundle('fr', 'digitalBrands', fr);
i18n.addResourceBundle('es', 'digitalBrands', es);

function DigitalBrands(props) {

  const { t } = useTranslation(['digitalBrands']);

  return (
        <div id="digital-brands" className="fix section-first">
            <div className="about-area ptb--40">
                <div className="about-wrapper">
                    <div className="container" style={{ paddingBottom: '20px' }}>
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-12 container-title">
                                <div className="section-titles service-style--3 mb--30 mb_sm--0">
                                    <h2 className="title-1 h2-title">{t('T1')}</h2>
                                </div>
                                <div className="section-titles service-style--3 mb--30 mb_sm--0">
                                    <h2 className="title-2 h2-title">{t('T2')}</h2>
                                </div>
                            </div>
                            <div className="col-lg-12 container-text">
                                <p className="h4-description" style={{ fontSize: '20px', color: '#fff' }}>
                                    {t('D1')}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="container container-img">
                        <div className="row row--35 align-items-center" style={{ display: 'flex', justifyContent: 'center'}}>
                            <div className="col-lg-12 container-headline">
                                <div className="section-titles service-style--3 mb--30 mb_sm--0">
                                    {/* <h3 style={{ fontWeight: '600' }} className="title">Choose the Brand</h3> */}
                                    <h3 style={{ fontWeight: '600' }} className="title"> {t('T3_1')} <span className="span-slash">//</span> {t('T3_2')} <span className="span-slash">//</span> {t('T3_3')}</h3>
                                </div>
                                {/* <div className="section-titles service-style--3 mb--30 mb_sm--0">
                                    <h2 style={{ fontWeight: '600' }} className="title"> you were looking for!</h2>
                                </div>
                                <div className="section-titles service-style--3 mb--30 mb_sm--0">
                                    <h1 style={{ fontWeight: '600' }} className="title-zoom">ENJOY OUR FOODS</h1>
                                </div> */}
                                <div className="section-titles service-style--3 mb--30 mb_sm--0" style={{ display: 'flex' }}>
                                    <p className="title">{t('T4_1')}<br/>{t('T4_2')}</p>
                                    <p className="title">{t('T5_1')} <br/>{t('T5_2')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  	)
}

export default DigitalBrands