const locale = {
    T1_1: 'Let\'s do it together...',
    T1_2: 'Beyond',
    T1_3: 'Dark.',
    T2: 'Atomic Kitchens Co-Active Model',
    T3: 'The Atomic Kitchens expert team will be in charge of:',
    T4: 'Overall brand management',
    T5: 'Tailor made marketing plan to sucessfully go to market',
    T6: 'Apps positioning and brand reputation',
    T7: 'Own e-commerce training. Manage all online orders easily through one central dashboard',
    T8: 'Recipe creation and kitchen layout assistence',
    T9: 'Food costing assistance',
    T10: 'Product demostration and global training'
};

export default locale;
