import React from "react";

function Disruption(props) {

  const positionText = {
    position: 'absolute',
    zIndex: 10,
    padding: '1rem'
  }

  return (
    <div id="disruption-now" className="disruption-now-latam fix">
      <div className="about-area">
        <div className="about-wrapper">
          <div className="container" style={{ padding: '15px' }}>
            <div className="row row--35 container-area" style={{ paddingTop: '30px' }}>
              <div className="col-lg-1 area-left">
              </div>
              <div className="col-lg-6 area-right align-items-center py-5">
                <h2 className="h2-title title-back-white">El Cambio es ahora</h2>
                <h4 className="h4-description"><strong>Las marcas digitales están aquí para quedarse.</strong></h4>
                <h4 className="h4-description block-mobile" style={{ fontWeight: '300' }}>¡Atomic Kitchens está aquí para <strong>liderar el camino!</strong></h4>
                <h4 className="h4-description" style={{ fontWeight: '300', margin: '1rem auto' }}>Estamos obsesionados con los consumidores y la tecnología. Usamos macrodatos y análisis de tendencias, marketing digital y entrenamiento para desarrollar marcas a domicilio que los consumidores desean.</h4>
                <h4 className="h4-description"><strong>¿Estás preparado para aumentar tus ganancias actuales del 30% a  más de un 80%?</strong></h4>
                <h4 className="h4-description" style={{ fontWeight: '300', marginBottom: '2rem' }}>Si es así, estamos preparados para activar nuestras marcas de Atomic en tu cocina y dar una nueva y segunda vida a tu negocio.</h4>
	            <h4 className="h4-description title-back-white">Somos un socio</h4>
	            <h4 className="h4-description title-back-white">de soluciones 360</h4>
              </div>
            </div>
            <div className="row row--35 container-footer">
	            <div className="col-lg-7 box-footer align-items-center">
		          	<div className="box-footer-left">
		          		<h4 className="h4-description"><strong>Atomic ></strong> </h4>
		          	</div>
		          	<div className="box-footer-right">
		          		<h4 className="h4-description">// capacidad para generar ingresos</h4>
		          		<h4 className="h4-description">// oportunidad para crear tu marca digital</h4>
		          		<h4 className="h4-description">// encuentra un socio experto en food-tech</h4>
		          	</div>
	            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Disruption