import React from "react";
import TabTwo from "../../elements/tab/TabTwo";

import i18n from '../../i18n/';
import { useTranslation } from 'react-i18next';
import en from '../../i18n/en/theDisruption';
import fr from '../../i18n/fr/theDisruption';
import es from '../../i18n/es/theDisruption';
i18n.addResourceBundle('en', 'theDisruption', en);
i18n.addResourceBundle('fr', 'theDisruption', fr);
i18n.addResourceBundle('es', 'theDisruption', es);

function TheDisruption(props) {
    const { t } = useTranslation(['theDisruption']);
    return (
        <div id="the-disruption" className="fix">
            <div className="about-area ptb--60">
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35">
                            <div className="col-lg-5 area-left">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/brand/atomic-brands.png" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-7 area-right">
                                <div className="about-inner inner">
                                    <div className="section-titles">
                                        <h3 className="h3-subtitle" style={{ fontWeight: '700' }}>{t('S1')}</h3>
									</div>
                                    <div style={{ paddingLeft: '15px' }} className="row mt--30 container-tabs">
                                        <TabTwo tabStyle="tab-style--1" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  	)
}

export default TheDisruption