import React, { Component }from "react";
import { FaCoins, FaOpencart } from "react-icons/fa";
import { AiOutlineFullscreenExit } from "react-icons/ai";

const ServiceList = [
    {
        icon: <FaCoins/>,
        title: 'Capex / Opex Saved',
        description: 'Without additional Capex or Opex, Atomic Kitchens can create new restaurant brands for ever-evolving trends, targets, and niches within just weeks instead of years.'
    },
    {
        icon: <AiOutlineFullscreenExit/>,
        title: 'Integrations',
        description: 'Fully integrated supply, inventory, kitchen, lead and delivery management.'
    },
    {
        icon: <FaOpencart/>,
        title: 'Food Delivery',
        description: 'Efficient last mile capabilities that uses own distribution and also leverages from integrations with aggregator apps.'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row row-service">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <div>
                                <div className="service service__style--2 heigth__box">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
