const locale = {
    D1_1: 'Be',
    D1_2: 'part of a',
    D2: 'trillion industry',
    D3: 'led by experts',
    D4_1: 'Become',
    D4_2: 'an',
    D5: 'AtomicPartner',
    D6: 'We are a 360',
    D7: 'solutions partner',
    T1: 'The Disruption is now',
    D8: 'Digital brands are here to stay.',
    D9_1: 'Atomic Kitchens is here to',
    D9_2: 'lead the way!',
    D10: 'We are obbsesed with customers and tech. We use big data & trend analysis, digital marketing and training, to develop delivery-only brands that consumers crave.',
    D11: 'Are you ready?',
    S1: 'We are.',
    D12_1: 'an',
    D12_2: 'atomic >',
    D13: 'earning capability',
    D14: 'digital brand opportunity?',
    D15: 'food-tech expert partner'
};

export default locale;
