const locale = {
    S1: 'Extra income opportunity',
    D1: 'Earn thousands in additional',
    D2: 'revenue each month.',
    D3: 'Get started within only few days!',
    S2: 'Get a lean an efficient operation powered by Atomic',
    //D4: 'Supplies centralized purchasing',
    D5: 'Low DeliveryApps commissions',
    D6: 'Ultra low-cost own e-commerce platform',
    D7: 'Last mile delivery agreements',
    D8: 'Packaging, branding & marketing',
    D9: 'Bulk food cost pricing',
};

export default locale;
