const locale = {
    L1: 'Become an AtomicPartner',
    L2: 'In the news',
    L3: 'About Us',
    L4: 'Investor relations',
    ENGLISH: 'English',
    SPANISH: 'Español',
    FRENCH: 'français'
};

export default locale;