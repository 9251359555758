import React from 'react';
import i18n from '../../i18n/';
import { useTranslation } from 'react-i18next';
import en from '../../i18n/en/together';
import fr from '../../i18n/fr/together';
import es from '../../i18n/es/together';
i18n.addResourceBundle('en', 'together', en);
i18n.addResourceBundle('fr', 'together', fr);
i18n.addResourceBundle('es', 'together', es);

function Together(props) {
  const style = {
    backgroundColor: '#ff0066',
  }

  const { t } = useTranslation(['together']);
  
  return(
    <div id="together-beyond" className="fix">
      <div className="about-area" style={style}>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35" style={{ display: 'grid', justifyItems: 'center' }}>
              <div className="col-lg-12 container-beyond">
                <div className='text-center beyond'>
                  <div className='dark'>
                    <h3 className="title-dark" style={{ fontWeight: 400 }}>{t('T1_1')} <span style={{ fontWeight: '600' }}>{t('T1_2')}</span>{t('T1_3')}</h3>
                    <h3 className='title-zoom'>{t('T2')}</h3>
                  </div>
                </div>

                <div className="beyond-description">
	                <h3 className="title-section">{t('T3')}</h3>
	                <ul className="my-1 underscore list-section" style={{ color: '#fff' }}>
	                  <li className='adjust'>
	                    <h4 className='h4-description mx-1 my-2' style={{ fontWeight: 400 }}>{t('T4')}</h4>
	                  </li>
	                  <li className='adjust'>
	                    <h4 className='h4-description mx-1 my-2' style={{ fontWeight: 400 }}>{t('T5')}</h4>
	                  </li>
	                  <li className='adjust'>
	                    <h4 className='h4-description mx-1 my-2' style={{ fontWeight: 400 }}>{t('T6')}</h4>
	                  </li>
	                  <li className='adjust'>
	                    <h4 className='h4-description mx-1 my-2' style={{ fontWeight: 400 }}>{t('T7')}</h4>
	                  </li>
	                </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Together