const locale = {
    T1: 'MARCAS',
    T2: 'DIGITALES',
    D1: 'El equipo de expertos de Atomic Kitchens usa inteligencia empresarial para diseñar menús que son altamente deseados por los consumidores, monitoreando sus preferencias, tendencias y ubicaciones... y muy fácil de implementar.',
    D2: 'Reduce cost. Get efficient',
    T3_1: 'Inteligente',
    T3_2: 'Eficiente',
    T3_3: 'Sostenible',
    T4_1: '¡Amamos a nuestros consumidores',
    T4_2: 'y ellos a nosotros!',
    T5_1: 'Piensa globalmente,',
    T5_2: 'cocina localmente',
};

export default locale;
