import React from 'react';

import i18n from '../../i18n/';
import { useTranslation } from 'react-i18next';
import en from '../../i18n/en/platformTastyboom';
import fr from '../../i18n/fr/platformTastyboom';
import es from '../../i18n/es/platformTastyboom';
i18n.addResourceBundle('en', 'platformTastyboom', en);
i18n.addResourceBundle('fr', 'platformTastyboom', fr);
i18n.addResourceBundle('es', 'platformTastyboom', es);

function PlatformTastyBoom(props) {

	const { t } = useTranslation(['platformTastyboom']);

  return(
    <div id="platform-tastyboom" className="fix bg_color--1">
      <div className="about-area py-5">
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35  align-items-center">
              <div className="col-lg-12 ">
	            <div className="row row--35  align-items-center">
	              <div className="col-lg-4 container-logo text-center" style={{ paddingBottom: '20px' }}>
	              	<a href="https://yourtastyboom.com/" target="_blank"><img className="w-100" src="/assets/images/logo/1-Online-ordering_atomic.png" alt="About Images" style={{ maxWidth: '200px'}}/></a>
	              </div>
	              <div className="col-lg-8 container-text">
	              	<p style={{ color: '#fff' }} dangerouslySetInnerHTML={{ __html: t('D1') }}></p>
	              </div>
	            </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlatformTastyBoom