import React, { Component } from "react";
import {Helmet} from 'react-helmet'

import i18n from '../../i18n/';
import { withTranslation } from 'react-i18next';
import en from '../../i18n/en/helmet';
import fr from '../../i18n/fr/helmet';
import es from '../../i18n/es/helmet';
i18n.addResourceBundle('en', 'helmet', en);
i18n.addResourceBundle('fr', 'helmet', fr);
i18n.addResourceBundle('es', 'helmet', es);
class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} || {this.props.t('TITLE')} </title>
                    <meta name="description" content="{this.props.t('CONTENT')}" />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default withTranslation(['helmet'])(PageHelmet);
