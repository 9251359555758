import React, { Component } from 'react';

class Footer extends Component{
    render(){
        const { footerCustomClass } = this.props
        return(
                <div className={`${footerCustomClass} footer-style-2 ptb--30 footer-container`} data-black-overlay="6">
                    <div className="wrapper plr--50 plr_sm--20">
                        <div className="row row-cols align-items-center justify-content-between">
                            <div className="col-content col-logo col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="inner" style={{display: 'flex'}}>
                                    <div className="logo text-center text-sm-left mb_sm--20">
                                        <a>
                                            <img src="/assets/images/logo/new_Atomic_Kitchens_Logo_Footer.png" alt="Atomic_Kitchens"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-content col-powered col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="inner text-center">
                                    {/*
                                    <p>
                                        <a>
                                            <img width="60" src="/assets/images/logo/tastyboom_copy.png" alt="TastyBoom"/>
                                        </a>
                                    	Own e-commerce platform powered by TastyBoom
                                    </p> <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                                        {SocialShare.map((val , i) => (
                                            <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                        ))}
                                    </ul> */}
                                </div>
                            </div>
                            <div className="col-content col-copyright col-lg-4 col-md-12 col-sm-12 col-12">
                                <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
                                    <div className="text">
                                        <p>Copyright © 2023 Atomic Kitchens Inc.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}



export default Footer;