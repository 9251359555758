const locale = {
    L1 : 'Devenir Partenaire Atomique',
    L2 : "Dans l'actualité",
    L3 : 'Informations sur l\'entreprise',
    L4 : 'Relations investisseurs',
    ENGLISH: 'English',
    SPANISH: 'Español',
    FRENCH: 'français'
};

export default locale;