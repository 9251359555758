const locale = {
    T7: '_En las noticias',
    D21_1: 'Nos encantan las relaciones públicas.',
    D21_2: ' ¡Bienvenidos periodistas e influencers! Tenemos historias atómicas que compartir con ustedes sobre la evolución del FoodTech y la revolución de las marcas digitales que estamos liderando.',
    D22: 'Comunicado de prensa, kit de medios y noticias de entrega, por favor póngase en contacto con nosotros en eduardo@isatomic.tech',
    D30_1: 'Portal PYME:',
    D30_2: 'Atomic Kitchens: Restaurantes aumentan cerca del 55% de sus ventas',
    D30_3: 'gracias a esta startup',
    D23_1: 'Diario Financiero',
    D23_2: 'Atomic Kitchens, la nueva apuesta de Humberto Fischer en el',
    D23_3: 'negocio de los restaurantes virtuales',
    D24_1: 'Radio Agricultura:',
    D24_2: 'Startup del sector Food-Tech y Dark Kitchens llega al mercado norteamericano',
    D25_1: 'E-negocios:',
    D25_2: 'Startup del sector Food-Tech y Dark Kitchens llega al mercado norteamericano',
    D26_1: 'The Times:',
    D26_2: 'Restauranteros aumentan cerca del 55% de sus ventas gracias a la Food-Tech chilena Atomic Kitchens',
    D27_1: 'Radio Infinita:',
    D27_2: 'Work Café Santander. Atomic Kitchens CEO interview',
    D27_3: 'Starting at: 30:30 minutes.',
    D28: '¡Más información e historias sabrosas aquí!',
    D29_1: 'Porque en un mundo tan convulsionado, necesitamos algo diferente.',
    D29_2: 'Capacitando a los líderes para vivir la vida con un propósito',
    D29_3: 'Obsesión por el cliente vs. Enfoque en el cliente:',
    D29_4: 'Los retos del marketing para satisfacer a los consumidores.',
    D29_5: 'Atomic Kitchens, un actor global en la disrupción de los ',
    D29_6: 'restaurantes virtuales.',
    D29_7: 'Rodolfo (Rudy) Palacio Schneider, cofundador y director general de Atomic Kitchens, afirma que los investigadores del Food-Market prevén que el sector de los restaurantes virtuales (cocinas fantasma o virtuales) podría alcanzar el billón de dólares a ',
    D29_8: 'finales de esta década.',
};

export default locale;
