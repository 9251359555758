const locale = {
    D1_1: 'Sé',
    D1_2: 'parte de una',
    D2: 'industria trillonaria',
    D3: 'liderada por expertos',
    D4_1: 'Conviértete',
    D4_2: 'en un',
    D5: 'AtomicPartner',
    D6: 'Somos un socio',
    D7: 'de soluciones 360',
    T1: 'El Cambio es ahora',
    D8: 'Las marcas digitales están aquí para quedarse.',
    D9_1: '¡Atomic Kitchens está aquí para',
    D9_2: 'liderar el camino!',
    D10: 'Estamos obsesionados con los consumidores y la tecnología. Usamos macrodatos y análisis de tendencias, marketing digital y entrenamiento para desarrollar marcas a domicilio que los consumidores desean.',
    D11: '¿Estás listo?',
    S1: 'Nosotros sí.',
    D12_1: 'an',
    D12_2: 'atomic >',
    D13: 'capacidad para generar ingresos',
    D14: 'oportunidad para crear tu marca digital',
    D15: 'encuentra un socio experto en food-tech'
};

export default locale;
