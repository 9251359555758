const locale = {
    ALERT_1: '¡Gracias por su mensaje, nos pondremos en contacto con usted en breve!',
    ALERT_2: 'Debes llenar los campos requeridos',
    D1: 'Si eres dueño u operador de una cocina con capacidad extra y estás buscando eficiencia e ingresos extra ¡Aquí estamos!',
    D2: 'Te apoyaremos desde la A a la Z.',
    T1: 'Nosotros sí.',
    S1: 'Hablemos',
    BEYOND: 'Beyond',
    DARK: 'Dark',
    FORM_NAME: 'Tu Nombre *',
    FORM_EMAIL: 'Tu Email *',
    FORM_SUBJECT: 'Escribe el asunto *',
    FORM_MESSAGE: 'Tu mensaje *',
    B_SUBMIT: 'Enviar',
};

export default locale;