const locale = {
    T1_1: 'Hagámoslo juntos...',
    T1_2: 'Beyond',
    T1_3: 'Dark.',
    T2: 'Modelo Coactivo de Atomic Kitchens',
    T3: 'Atomic se encarga de:',
    T4: 'Gestión y marketing de la marca',
    T5: 'Posicionamiento en aplicaciones de entrega y reputación de la marca',
    T6: 'Retiro de pedidos',
    T7: 'Coste y manejo ultra eficiente de los alimentos',
};

export default locale;
