const locale = {
    T1: 'avoir plus',
    T2_1: 'orders',
    T2_2: 'revenue',
    T3: 'in your Kitchen now',
    T4: 'Atomic Brands',
    T5: 'as seen on our own',
    T6: 'e-commerce platform',
    T7: 'Become an AtomicPartner',
    T8: 'Turn your extra capacity into',
    T9: 'an Atomic Digital Brand',
    D1: 'Get  a supplemental income. Expand your current kitchen or open a new one with one of our atomic digital brands.',
    D2: 'We understand the problems and needs of restaurateurs and we know the solution.',
    D3: 'Atomic Kitchens is led by a team of entrepreneurs and experts with extensive background in tech, finance, food and marketing in high-growth companies with valuations over $1 billion',
    D4: 'These backgrounds are the best recipe for an exponential growth strategy in all the countries we operate and activate kitchens.',
    D5: 'A team of great leaders with a proven track record working for you daily and face to face! Another reason to go BeyondDark.',
    CT: 'Are you ready?',
};

export default locale;
