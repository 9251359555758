const locale = {
    S1: 'Oportunidad de ingresos extra',
    D1: 'Gana miles en ganancias',
    D2: 'adicionales cada mes.',
    D3: '¡Comienza en sólo unos días!',
    S2: 'Obtén una operación económica y eficiente con la ayuda de Atomic',
    //D4: 'Compra centralizada de proveedores',
    D5: 'Comisiones bajas en aplicaciones de entrega',
    D6: 'Plataforma propia de comercio electrónico de muy bajo costo',
    D7: 'Acuerdos de entrega de última milla',
    D8: 'Packaging, marca y marketing',
    D9: 'Precios de costo de los alimentos en gran volumen',
};

export default locale;
