const locale = {
    T1: 'Obtén más',
    T2_1: 'órdenes',
    T2_2: 'ganancias',
    T3: 'en tu cocina ahora',
    T4: 'Marcas Atómicas',
    T5: 'como las vistas en nuestro',
    T6: 'propio e-commerce',
    T7: 'Conviértete en un AtomicPartner',
    T8: 'Transforma tu capacidad extra en',
    T9: 'una Marca Atómica Digital',
    D1: 'Obtén ganancias adicionales. Expande tu cocina actual o abre una nueva con una de nuestras marcas digitales.',
    D2: 'Entendemos los problemas y necesidades de los restauranteros y conocemos la solución.',
    D3: 'Atomic Kitchens está dirigida por un equipo de emprendedores, expertos con amplia experiencia en tecnología, finanzas, food-tech y marketing en empresas de alto crecimiento, con valoraciones superiores a los mil millones de dólares.',
    D4: 'Estos conocimientos son la mejor receta para una estrategia de crecimiento exponencial en todos los países en los que operamos y activamos cocinas.',
    D5: '¡Un equipo de grandes líderes con una trayectoria profesional comprobada trabajando para ti diariamente, cara a cara! Otra razón para ir BeyondDark',
    CT: '¿Estás listo?',
};

export default locale;
