const locale = {
    L1: 'Conviértete en un AtomicPartner',
    L2: 'En las noticias',
    L3: 'Sobre Nosotros',
    L4: 'Invierte',
    ENGLISH: 'English',
    SPANISH: 'Español',
    FRENCH: 'Français'
};

export default locale;