import React from 'react'

function TeamDNA ({ team }) {

    return (
      <div className="col-lg-4 col-12 dna-profile">
        <div className="single-column">
          <div className="thumbnail">
            <img style={{ width: "150px", height: "150px" }} src={ team.img } alt="About Images"/>
          </div>
            <h4 className="title">{ team.name }</h4>
            <p className="title-2">{ team.title }</p>
          <br/>
            <p className="description h4-description">{ team.description }</p>
          <br/>
        </div>
      </div>
    );
}

export default TeamDNA