const locale = {
    TEAM_T1: 'Cofundador y Publicidad',
    TEAM_D1: 'Rodolfo, más conocido como Rudy, tiene más de 8 años de experiencia en el sector de los restaurantes canadienses y un amplio conocimiento de sus necesidades específicas de marketing operativo y publicidad. Fundó y dirigió TastyBoom, una plataforma de pedidos de comida de marca blanca pionera y visionaria que, hasta la fecha, ha procesado pedidos con ingresos de 8 cifras. Rudy es un verdadero constructor de negocios que se involucra fuertemente en las operaciones del día a día con el fin de aprender y encontrar la manera de hacer las cosas de manera diferente y más eficiente, con un historial real de entrega de resultados, la innovación y los resultados positivos. Se licenció en Comunicación de Masas en la especialidad de Publicidad y realizó estudios de postgrado en Diseño.',
    TEAM_T2: 'CEO y Cofundador',
    TEAM_D2: 'Uriel es un emprendedor de FoodTech en Latam. Con más de 15 años de experiencia en negocios digitales, es un experto en comercio electrónico, tecnología y desarrollo de restaurantes digitales. Como emprendedor en serie ha liderado negocios con resultados sobresalientes, enfocándose en la consolidación y expansión de sus proyectos emprendedores en Argentina y otros mercados de Latam logrando una importante trayectoria. Desde 2014, Uriel trabaja en el sector gastronómico y vislumbra la disrupción de la industria, siendo pionero en las Dark Kitchens en Latam. Después de lograr su licenciatura en Administración de Empresas, volcó todos sus conocimientos y habilidades en la adaptación del comercio electrónico para la industria gastronómica.',
    TEAM_T3: 'Cofundador y Analista/Controlador',
    TEAM_D3: 'Daniel es un empresario latinoamericano con una amplia experiencia en la alta dirección y en el desempeño de funciones clave de liderazgo en empresas de TI y telecomunicaciones en América Latina. También ha sido CEO de Planex Technologies e Intratel Networks. Daniel tiene casi una década de trayectoria empresarial relevante en la industria de las Dark Kitchens. En 2014 cofundó el Grupo Markopolis, desarrollando un nuevo modelo de negocio revolucionario en la región: la primera empresa de Dark Kitchens en Latam, desplegando con éxito 15 marcas virtuales. Trabajando como CFO, se centró en el desarrollo global y la eficiencia general del nuevo modelo, creando Deli Data, un sistema de Big Data & Business Intelligence centrado en la comprensión del nuevo comportamiento del consumidor digital. Es licenciado en Ingeniería Industrial por la Universidad Tecnológica Nacional (Argentina).',
    TEAM_T4: 'Cofundador y CFO',
    TEAM_D4: 'Cristian aporta 20 años de experiencia en los mercados financieros a la sala de juntas. Pasó sus primeros 10 años en el Santander y el Royal Bank of Scotland. En 2011, se convirtió en socio de Addwise Wealth Management con 1.000 millones de AUM, y desarrolló una correduría de fondos de inversión privados y factoring. Formado en la Universidad Complutense de Madrid, Cristian es licenciado en Economía y tiene un Máster en Finanzas.',
    TEAM_T5: 'Director de Marketing',
    TEAM_D5: 'Eduardo cuenta con una relevante trayectoria tanto en el ámbito profesional como académico en Latam. Más allá de su enorme pasión por la enseñanza y la creación de programas de educación ejecutiva, ha desempeñado un papel importante en empresas multinacionales como LG, Movistar, Yamaha y Nissan, liderando departamentos de marketing y ventas, así como importantes proyectos comerciales a gran escala en los sectores de la banca, la tecnología y el comercio minorista, como director de una consultoría de negocios con sede en Palo Alto para los mercados de América Latina. Eduardo tiene un MBA por la Escuela de Negocios y Derecho ESERP, donde se centró en Emprendimiento y Marketing, un Master en Comunicación Estratégica por la Universidad de Barcelona y realizó programas profesionales en Transformación Digital/Marketing Digital en Columbia y MIT.',
    TEAM_T6: 'CTO',
    TEAM_D6: 'Ingeniera de sistemas con experiencia en el área de desarrollo e implementación de páginas web así como en la lógica de negocio de servicios de comida rápida online, con amplia experiencia en atención al cliente proporcionando diversas plataformas con múltiples tecnologías. Como líder de desarrollo fullstack, ha estado inmersa en la construcción y diseño de varios proyectos orientados al área, que han permitido el crecimiento de la venta y compra de productos, brindando a los clientes mayor seguridad y confianza.',
    TEAM_T8: 'Miembro de la Junta',
    TEAM_D8: 'Ricardo es un activo emprendedor e inversor de capital de riesgo que ha fundado, escalado globalmente y ha conseguido varios exitos con empresas tecnológicas, entre ellas Azumi y Frontier Car Group. Es socio de Manutara Ventures, un fondo de inversión latinoamericano centrado en empresas tecnológicas de América. Ricardo tiene un MBA por la Escuela de Negocios ESEM.',
    TEAM_T9: 'Presidente de la Junta',
    TEAM_D9: 'Humberto es un emprendedor en serie latinoamericano y cofundador de Aquachile, el mayor productor de salmón de Chile, que se vendió en 2019 con una capitalización de mercado de 800 millones de dólares. Fue cofundador de la cadena Sun Dreams, que ha crecido hasta tener once casinos y hoteles en cuatro países latinoamericanos. Humberto forma parte del consejo de administración de 17 empresas y disfruta del desarrollo de bienes raíces comerciales en todo el continente latino con sus hijos.',
    TEAM_T10: 'Asesor',
    TEAM_D10: 'Francisco cofundó Lomi, una plataforma de entrega de comestibles del mercado local en Chile, y anteriormente se desempeñó como Head of Growth en Delivery Hero Chile. En el pasado, dirigió con éxito proyectos estratégicos sobre zonificación competitiva, liderazgo de marca, operaciones de cocina y logística, así como marketing de plataformas digitales.',
    T1: 'Las marcas digitales están aquí para quedarse',
    T2: '¡Atomic Kitchens está aquí para liderar el camino!',
    T3_1: 'Activando cocinas alrededor del mundo,',
    T3_2: 'Beyond',
    T3_3: 'Dark',
    T4_1: 'El verdadero Cambio es',
    T4_2: '_ahora',
    T4_3: '_beyondDark',
    T5: '_Quiénes somos',
    D5_1: 'Atomic kitchens es una empresa de FoodTech de rápido crecimiento que aspira a convertirse en un actor global en la evolución rápida y de alto crecimiento de la disrupción de las marcas digitales.',
    D5_2: 'Actualmente, Atomic Kitchens está desarrollando, abriendo y operando varias marcas digitales habilitadas por tecnología y big data en ubicaciones estratégicas a lo largo de Latam y Norteamérica.',
    D5_3: 'En Atomic, comenzando desde la tecnología al producto, vamos más allá de Dark Kitchens implementando un modelo coactivo en el que extendemos nuestras marcas digitales a través de nuestra cocina y la de terceros.',
    D5_4: 'Creamos marcas y menús deliciosos basados en el análisis de datos y en potentes estrategias de marketing creativo centradas en el nuevo comportamiento del consumidor electrónico. Estamos obsesionados con los clientes y socios. ¡Los amamos y ellos nos aman también!',
    T6_1: 'Conviértete en un',
    T6_2: 'AtomicPartner',
    S7: 'La Cultura Atomic',
    D7: 'Impulsada por el negocio',
    D8: 'centrada en las personas',
    D9: 'En Atomic Kitchens amamos lo que hacemos. Nuestro capital humano forma un equipo global, multicultural, comprometido y cualificado que ayuda a crear valor sostenible, a través del talento y el trabajo duro de sus miembros.',
    D10: 'Nuestros líderes de Atomic tienen el reto de llevar a cabo negocios sostenibles, responsables y centrados en las personas. Hacemos líderes centrados en las personas en todo el mundo.',
    D11: 'Nuestra identidad pretende desarrollar estrategias constantes y acciones diarias que permitan a nuestros miembros ser más felices y estar altamente comprometidos.',
    D12: '"Ser miembro del equipo de Atomic Kitchens no es sólo un trabajo, es una vocación colectiva. Nuestro equipo es parte de nuestro proyecto de éxito empresarial" Rudy Palacio Schneider - Fundador y CEO de Atomic Kitchens.-',
    D13: 'El enfoque de Atomic Kitchens sobre el impacto social coactivo se basa en el esfuerzo por crear un cambio significativo y duradero en las siguientes áreas:',
    D14: 'Igualdad e inclusión',
    D15: 'Asegurar nuestro futuro',
    D16: 'Cuidar de nuestro mundo',
    D17: 'Por ello, nuestro compromiso con el trabajo de calidad, la formación, el desarrollo profesional y el respeto a la diversidad, la igualdad de oportunidades y la no discriminación nos convierten en una de las empresas de tecnología alimentaria favoritas para trabajar. Nuestros líderes de Atomic se preocupan por las personas.',
    T6: '_El ADN de Atomic y su equipo directivo',
    D18: 'Atomic Kitchens está dirigida por un equipo de emprendedores, expertos con amplia experiencia en tecnología, finanzas, food-tech y marketing en empresas de alto crecimiento, con valoraciones superiores a los mil millones de dólares.',
    D19: 'Estos conocimientos son la mejor receta para una estrategia de crecimiento exponencial en todos los países en los que operamos y activamos cocinas.',
    D20_1: '¡Un equipo de grandes líderes con una trayectoria profesional comprobada trabajando para ti diariamente,',
    D20_2: 'cara a cara!',
    D20_3: ' Otra razón para ir ',
    BEYOND: 'Beyond',
    DARK: 'Dark',
};

export default locale;