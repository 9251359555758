import React, { Component } from "react";
import { FiUsers } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiUsers />,
        title: 'We love our users!',
        sub_title: 'We are obssessed about customers.',
        description: 'We love our Customers! Atomic Kitchens is driven by tech and data – because it tells us what our users experience and what satisfies them. We will always go above and beyond in providing consumers with an outstanding experience through world-class innovation, services, and products.',
        sub_title2: 'We love our customers, and they love us too!',
        description2: 'Our performance on platforms and apps is atomic. We have very high scores (over the industry) in customer satisfaction, reviews, purchase intent and brand recommendation. The brands we create, the menus and communication strategies place us in the best rankings.'
    }
]

class ServiceTwo extends Component{
    render(){
        let title = 'Powered by Consumers',
        description = 'We’re big on people, both our staff and our customers, because life isn’t just about Big Data';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row mt--30 service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12" key={i}>
                                    <div>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                {/* <h3 className="title">{val.title}</h3> */}
                                                <h3 className="title">{val.sub_title}</h3>
                                                <p>{val.description}</p>
                                                <h3 className="title mt--30">{val.sub_title2}</h3>
                                                <p>{val.description2}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
