const locale = {
    T1: 'DIGITAL',
    T2: 'BRANDS',
    D1: 'Atomics Food\'s team of experts uses business intelligence to design smart menus that are highly desired by customers, monitoring their preferences, trends and locations…and very easy to implement.',
    D2: 'Reduce cost. Get efficient',
    T3_1: 'Smart',
    T3_2: 'Efficient',
    T3_3: 'Sustainable',
    T4_1: 'We love our customers',
    T4_2: 'and they love us too!    ',
    T5_1: 'Think globally,',
    T5_2: 'cook locally',
};

export default locale;
