import React, { Component } from "react";

class TabsTwo extends Component{
    render(){
        const joinStyle = {
            width: '110%'
        }
        const divButtons = {
            marginTop: '3rem',
            width: 550
        }

        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="single-tab-content tab-custom-ul">
                                    <ul>
                                        <li>
                                            Rodolfo (Rudy) Palacio Schneider <span>-</span> <strong>CEO & Co-Founder </strong>
                                        </li>
                                        <li>
                                            Cristian Rodriguez <span>-</span> <strong>Co-Founder </strong>
                                        </li>
                                        <li>
                                            Eduardo Lagunas <span>-</span> <strong>Marketing Director</strong>
                                        </li>
                                        <li>
                                            Ricardo Donoso <span>-</span> <strong>Board Member</strong>
                                        </li>
                                        <li>
                                            Humberto Fischer <span>-</span> <strong>President of the board</strong>
                                        </li>
                                        {/*<li>
                                            Francisco Leitao <span>-</span> <strong>Advisor</strong>
                                        </li> */}
                                    </ul>
                                    <div className="rn-columns-area pb--120 bg_color--5" style={divButtons}>
                                        <div className="dna">
                                            <div className="row">
                                                <div className="col-12 mt_sm--30 mt_md--30 mb_md--30">
                                                    <div className="single-column">
                                                        <a className="rn-button-style--2 btn-solid more" href="/Atomic-Kitchens-dna">Learn More</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 mt_sm--30 mt_md--30 mb_md--30">
                                                    <div className="single-column" style={joinStyle}>
                                                        <a className="rn-button-style--2 btn-solid" href="/join-our-team">Join Atomic</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;