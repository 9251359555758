// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './i18n/';

// Create Import File
import './index.scss';
import './custom.css';
import './custom0x.css';
import './custom1x.css';
import './custom2x.css';
import './customLanding.css';

// Common Layout
// import Layout from "./component/common/App";

// Dark Home Layout 
import DarkPortfolioLanding from './dark/PortfolioLanding';
import LandingCa from './dark/LandingCa';
import LandingCl from './dark/LandingCl';

// Element Layout
import error404 from "./elements/error404";

// Blocks Layout
import Columns from "./blocks/Columns";
import Investors from './elements/Investors';
// Blocks Layout
import NewsAndInfo from "./blocks/NewsAndInfo";
import Corporate from "./blocks/Corporate";
import Latam from "./blocks/Latam";

import { BrowserRouter, Switch, Route  } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import Iframe from './blocks/Iframe';

class Root extends Component{
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path={`${process.env.PUBLIC_URL}/`} component={DarkPortfolioLanding}/> 
                    <Route path={`${process.env.PUBLIC_URL}/investors`} component={Investors} />    
                    <Route exact path={`${process.env.PUBLIC_URL}/news`} component={NewsAndInfo}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/corporate-info`} component={Corporate}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/partners`} component={Latam}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/landing`} component={LandingCa}/>
                    <Route exact path={`${process.env.PUBLIC_URL}/landing-latam`} component={LandingCl}/>
                    <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
                    <Route component={error404}/>

                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.unregister();