import React from "react";
import Helmet from "../component/common/Helmet";
import HeaderThree from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import { useState } from "react";
import i18n from '../i18n/';
import { useTranslation } from 'react-i18next';
import en from '../i18n/en/investors';
import fr from '../i18n/fr/investors';
import es from '../i18n/es/investors';
i18n.addResourceBundle('en', 'investors', en);
i18n.addResourceBundle('fr', 'investors', fr);
i18n.addResourceBundle('es', 'investors', es);

function Investors(props) {

  const { t } = useTranslation(['investors']);

  const [user, setUser] = useState('')
  const [pass, setPass] = useState('')

  const handleChange = event => setUser(event.target.value)

  const handlePassword = event => setPass(event.target.value)
  
  const submit = event => {
    event.preventDefault();

    if (pass === "" || user === "") {
      alert(t('ALERT_1'))
    } else {
      alert(t('ALERT_2'))
    }
  }

  return (
    <div className="active-dark">
      <Helmet pageTitle="Atomic Kitchens" />

      <HeaderThree
        headerClassName="sticky"
        homeLink="/"
        logo="symbol-dark"
        color="color-black"
      />

      <div id="investors" className="fix">
        <div className="slider-wraper">
          <div className="slide slider-paralax slider-style-3 d-flex align-items-center justify-content-center">
            <div className="container">
              <div style={{ position: 'relative' }} className="row">
                <div className="col-lg-6 py-2  container-text">
                  <h3 className="investor-title">{t('T1')} <span style={{ fontWeight: '600' }}>{t('BEYOND')}</span><span style={{ fontWeight: '400' }}>{t('DARK')}</span>.</h3>
                  <h3 className="investor-title">{t('T2')}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="investor-relations" className="fix">
        <div className="about-area ptb--50 bg-atomic">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center container-area">
                <div className="col-lg-12 area-right">
                  <h2 className="title h2-title">{t('T3')}</h2>
                  <br />
                  <h4 className="h4-description" style={{ fontWeight: '400' }}>
                    {t('T4')}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="login" className="fix">
        <div className="about-area ptb--60">
          <div className="about-wraper">
            <div className="container d-flex justify-content-center">
              <form onSubmit={submit} style={{ width: 'auto' }}>
                <div className="form-group">
                  <input style={{ color: '#000' }} type="text" className="form-control" placeholder={t('I1')} value={user} onChange={handleChange}/>
                </div>
                <div className="form-group">
                  <input style={{ color: '#000' }} type="password" className="form-control" placeholder={t('I2')} value={pass} onChange={handlePassword}/>
                </div>
                <div className="form-group">
                  <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit">{t('B1')}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

       <Footer footerCustomClass="bg_color--5" />
    </div>
  )
}

export default Investors