import React from "react";
import { BsHeart } from 'react-icons/bs'
import { FaRegSmileBeam, FaChartLine } from 'react-icons/fa'
import { GiChefToque, GiTakeMyMoney } from 'react-icons/gi'

import i18n from '../../i18n/';
import { useTranslation } from 'react-i18next';
import en from '../../i18n/en/infoIcon';
import fr from '../../i18n/fr/infoIcon';
import es from '../../i18n/es/infoIcon';
i18n.addResourceBundle('en', 'infoIcon', en);
i18n.addResourceBundle('fr', 'infoIcon', fr);
i18n.addResourceBundle('es', 'infoIcon', es);

function InfoIcon(props) {

  const { t } = useTranslation(['infoIcon']);

  return (
    <div id="info-icon" className="fix" style={{ backgroundColor: '#fff' }}>
      <div className="about-area">
        <div className="about-wrapper row container-box-icon">
          <div className="col-lg-6 border-r-atomic border-b-atomic box-icon">
            <div className="icon text-center color-atomic text-xl">
              <BsHeart />
            </div>
            <h4 className="color-atomic">
              {t('T1')}
            </h4>
          </div>
          <div className="col-lg-6 border-l-atomic border-b-atomic box-icon">
            <div className="icon text-center color-atomic text-xl">
              <FaRegSmileBeam />
            </div>
            <h4 className="color-atomic">
              {t('T2')}
            </h4>
          </div>
          <div className="col-lg-6 border-t-atomic border-r-atomic box-icon">
            <div className="icon text-center color-atomic text-xl">
              <GiChefToque />
            </div>
            <h4 className="color-atomic">
              {t('T3')}
            </h4>
          </div>
          <div className="col-lg-6 border-t-atomic border-l-atomic box-icon">
            <div className="icon text-center color-atomic text-xl">
              <FaChartLine /> 
            </div>
            <h4 className="color-atomic">
              {t('T4')}
            </h4>
          </div>
        </div>
      </div>
      <div className="bg-atomic w-100 about-area info-icon-footer">
        <h3 className="py-3 px-5" style={{ fontWeight: 400 }}>{t('T5')}</h3>
      </div>
    </div>
  )
}

export default InfoIcon