const locale = {
    D1: 'Aumenta tus números cada mes',
    D2: 'Reduce costos. Sé más eficiente',
    D3: 'Expande tu',
    D4: '_pasión',
    D5: '_negocio',
    S1: 'Hazlo',
    T1: 'Beyond',
    T2: 'Dark',
    D6: 'Explora nuevos mercados, expande tu alcance y aumenta tus ganancias con nuestro modelo Atómico Coactivo.',
    D7: 'Atomic Kitchens está listo para llevarte un paso adelante en el futuro.'
};

export default locale;
