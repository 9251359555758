import React, { Component } from "react";
// import { FaLanguage } from "react-icons/fa";

import i18n from "../i18n";
import { withTranslation } from "react-i18next";
import NavDropdown from "react-bootstrap/NavDropdown";
import en from "../i18n/en/linkMenu";
import fr from "../i18n/fr/linkMenu";
import es from "../i18n/es/linkMenu";
i18n.addResourceBundle("en", "linkMenu", en);
i18n.addResourceBundle("fr", "linkMenu", fr);
i18n.addResourceBundle("es", "linkMenu", es);


function changeLanguage(lng) {
  i18n.changeLanguage(lng);
  localStorage.setItem("lang", lng);
}
class LangMenu extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    const { fromHome } = this.props;
    let routePrefix = fromHome ? "/#" : "#";
    return (
      <>
          <NavDropdown
            title={
              <span style={{textTransform: "uppercase"}}>
                {localStorage.getItem("lang") || 'es'}
              </span>
            }
            id="nav-dropdown"
            style={{
              marginTop: "12px",
              marginBottom: "4px",
              marginRight: "4px",
              fontSize: "16px",
              fontWeight: "500",
            }}
            className="position-absolute end-0 justify-content-end"
          >
            {localStorage.getItem("lang") !== "en" && (
              <>
                <NavDropdown.Item onClick={() => changeLanguage("en")}>
                  <div style={{ color: "#1d1d24" }}>
                    {this.props.t("ENGLISH")}
                  </div>
                </NavDropdown.Item>
              </>
            )}
            {localStorage.getItem("lang") !== "es" && (
              <>
                <NavDropdown.Item onClick={() => changeLanguage("es")}>
                  <div style={{ color: "#1d1d24" }}>
                    {this.props.t("SPANISH")}
                  </div>
                </NavDropdown.Item>
              </>
            )}
          </NavDropdown>
      </>
    );
  }
}
export default withTranslation(["linkMenu"])(LangMenu);
