import React, { useEffect } from 'react';
import PageHelmet from '../component/common/Helmet';
import HeaderThree from '../component/header/HeaderThree';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/Footer";


const Iframe = () => {
    const iframeScript = () => {
        (function(){
            var r,d=document,gt=d.getElementById,cr=d.createElement,tg=d.getElementsByTagName,id="aidaform-embed";
            
            if(!gt.call(d,id)){
                r=cr.call(d,"script");
                r.id=id;r.src="https://embed.aidaform.com/embed.js";
                (d.head || tg.call(d,"head")[0]).appendChild(r);
            }
        })()
    }

    useEffect(() => {
        iframeScript();
    }, []);

    return (
        <>
            <PageHelmet pageTitle="Join Atomic Kitchens" />

            <HeaderThree headerClassName="sticky" homeLink="/" logo="symbol-dark" color="color-black" />
            {/* End Header Area  */}

            {/* Start Page Wrapper  */}
            <main className="page-wrapper page-team">
                <div className="rn-columns-area ptb--120 bg_color--5 section-adn">
                    <div className="container">
                        <div className="row">
                            {/* <iframe className="iframe__style" key={atomic} id="0pp3r8yyxwgk" src="https://atomic.aidaform.com/join_our_team" frameBorder="0" scrolling="no" allowpaymentrequest="" allow="fullscreen *; camera *; microphone *; autoplay *" style={{ border: 'none', width: '100%', height: 4613, minHeight: 200 }} title="Basic Job Application Form"></iframe> */}
                            <div id="formAtomic" style={{ width: '100%' }} data-aidaform-widget="form-2019-12" data-url="https://atomic.aidaform.com/join_our_team" data-width="100%" data-height="500px" data-do-resize></div>
                        </div>
                    </div>
                </div>
            </main>
            {/*End Page Wrapper */}
                    
            {/* Start Back Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
            {/* Start Footer Area  */}
            <Footer footerCustomClass="footer-team bg_color--5" />
            {/* End Footer Area  */}

        </>
    )
}

export default Iframe;