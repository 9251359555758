const locale = {
    TEAM_T1: 'Co-Founder & Marketing',
    TEAM_D1: 'Rodolfo, better known as Rudy, has over 12 years of experience in the Canadian food-tech industry and extensive knowledge of its specific operational marketing and advertising needs. He founded and directed TastyBoom a first of its kind and visionary white label food-ordering platform which to-date has processed orders revenues in the 8 figures range. Rudy is a real business builder who gets heavily involved in day-to-day operations in order to learn and find how to do things differently and more efficient boasting a real track record of delivering results innovation and positive outcomes. He received his bachelor’s in Mass Communications majoring in Advertising and conducted post-graduate studies in Design.',
    TEAM_T2: 'CEO & Co-Founder',
    TEAM_D2: 'Uriel is a FoodTech entrepreneur in Latam. With over 8 years experience in digital business, he is an expert in e-commerce, tech and digital restaurants development. As a serial entrepreneur he has led businesses to outstanding results, focused on the consolidation and expansion of his entrepreneurial projects in Argentina and other Latam markets achieving a significant track record. Since 2014, Uriel has been working in the gastronomic sector and envisioning the disruption of the industry, being a pioneer in the dark kitchens in Latam. After receiving his bachelor\'s degree in Business Administration, he turned all his knowledge and skills into adapting e-commerce for the gastronomic industry.',
    TEAM_T3: 'Co-Founder & Analitycs/Controller',
    TEAM_D3: 'Daniel, is a Latin American entrepreneur with extensive experience in senior top management and holding key leadership roles in IT & Telecommunications companies in Latam. He has also served as CEO of Planex Technologies & Intratel Networks. Daniel has nearly a decade of a relevant entrepreneurial track record in the Dark Kitchens industry. In 2014 he co-founded the Markopolis Group, developing a new disruptive business model in the region: the first Dark Kitchens company in Latam, deploying successfully 15 Virtual Brands. Working as CFO, he focused in the global development and overall efficiency of the new model, creating Deli Data, a Big Data & Business Intelligence System focused on the understanding of the new digital e-consumer behavior. He has a bachelor\'s degree in Industrial Engineering.',
    TEAM_T4: 'Co-Founder & CFO',
    TEAM_D4: 'Cristian brings 20 years of experience in the financial markets to the boardroom table. He spent his first 10 years at Santander and the Royal Bank of Scotland. In 2011, he became a partner at Addwise Wealth Management with 1 Billion AUM, and developed a brokerage for private investment funds and factoring. Educated at the Complutense University of Madrid, Cristian earned an Economics degree and a Master in Finance.',
    TEAM_T5: 'Marketing Director',
    TEAM_D5: 'Eduardo has a relevant background in both professional and academic areas in Latam. Beyond his enormous passion for teaching and creating executive education programs, he has played an important role in multinational companies such as LG, Movistar, Yamaha and Nissan, leading -marketing and sales- departments as well as important large-scale commercial projects in Bank, Tech and Retail industries as a Country Manager of a Business Consultancy based in Palo Alto for Latam markets. Eduardo holds an MBA from ESERP School of Business and Law, where he focused on Entrepreneurship and Marketing, a Master in Strategic Communication from the University of Barcelona and conducted professional programs in Digital Transformation/Digital Marketing at Columbia and MIT.',
    TEAM_T6: 'CTO',
    TEAM_D6: 'Systems engineer with experience in the area of development and implementation of web pages as well as in the business logic of online fast food services, with extensive experience in customer service providing various platforms with multiple technologies. As a fullstack development leader, she has been immersed in the construction and design of several projects oriented to the area, which have allowed the growth of the sale and purchase of products, providing customers with greater security and confidence.',
    TEAM_T8: 'Board Member',
    TEAM_D8: 'Ricardo is an active entrepreneur and venture capital investor that has founded, scaled globally and successfully exited several tech companies, among them Azumi and Frontier Car Group. He is a partner of Manutara Ventures, a Latin American investing vehicle focused on tech companies in the Americas. Ricardo holds an MBA from the ESEM Business School.',
    TEAM_T9: 'President of the board',
    TEAM_D9: 'Humberto is a Latin American serial entrepreneur and cofounder of Aquachile, Chile’s largest Salmon producer which sold in 2019 at a market cap of USD 800MM. He cofounded the Sun Dreams chain, which has grown to eleven casinos and hotels in four Latin American countries. Humberto sits on the board of 17 companies and enjoys developing commercial real estate across the Latin continent with his sons.',
    TEAM_T10: 'Advisor',
    TEAM_D10: 'Francisco cofounded Lomi, a local market grocery delivery platform for Chile, and previously served as Head of Growth at Delivery Hero Chile. In the past, he successfully led strategic projects on competitive zoning, brand leadership, kitchen operations and logistics, as well as digital platform marketing.',
    T1: 'Digital brands are here to stay',
    T2: 'Atomic Kitchens is here to lead the way!',
    T3_1: 'Activating kitchens worldwide,',
    T3_2: 'Beyond',
    T3_3: 'Dark & Co-active',
    T4_1: 'True Disruption is',
    T4_2: '_now',
    T4_3: '_beyondDark',
    T5: '_Who we are',
    D5_1: 'Atomic Kitchens is a fast-growing FoodTech company aiming to become a global player in the rapidly evolving, high-growth Digital Brands disruption.',
    D5_2: 'Atomic Kitchens is currently developing, opening and operating several digital brands enabled by technology and big data in strategic locations across Latam & North America.',
    D5_3: 'At Atomic, starting from tech to product, we go beyond dark kitchens, implementing a co-active model in which we deploy our Digital Brands through our own & third party kitchens.',
    D5_4: 'We create delightful brands and menus based on data analysis and powerful creative marketing strategies focused on the new e-consumer behavior. We are obsessed with customers and partners. We love them and they love us too!',
    T6_1: 'Become an',
    T6_2: 'AtomicPartner',
    S7: 'The Atomic Culture',
    D7: 'Business driven',
    D8: 'people-centered',
    D9: 'In Atomic Kitchens we love what we do. Our human capital form a global, multicultural, committed and qualified Team that helps to create sustainable value, through the talent and hard work of its members.',
    D10: 'Our Atomic leaders are challenged to conduct sustainable, responsible and people-centered businesses. We make people-focused leaders worldwide.',
    D11: 'Our Identity aims to develop constant strategies and daily actions that allow our members to be happier and highly committed.',
    D12: '"Being a member of the Atomic Kitchens team is not just a job—it’s a collective calling. Our team is part of our entrepreneurial success project" Rudy Palacio Schneider - Atomic Kitchens Founder & CEO.',
    D13: 'Atomic Kitchens\'s approach to co-active social impact is rooted in striving to create meaningful, lasting change in the following areas:',
    D14: 'Equity and Inclusion',
    D15: 'Securing our Future',
    D16: 'Caring for our world',
    D17: 'That is why our commitment to quality jobs, training, professional development and respect for diversity, equal opportunities and non-discrimination make us one of the favourite food-tech companies for people to work at. Our Atomic leaders care about people.',
    T6: '_Atomic\'s DNA & Management Team',
    D18: 'Atomic Kitchens is led by an entrepreneurial team of experts with extensive experience in tech, finance, food, marketing, and in growing and scaling companies globally & to valuations over 1 Billion USD.',
    D19: 'These backgrounds are the best recipe for an exponential growth strategy in all the countries we operate and activate kitchens.',
    D20_1: 'A team of great leaders with a proven track record working for you daily and',
    D20_2: 'face to face!',
    D20_3: 'Another reason to go',
    BEYOND: 'Beyond',
    DARK: 'Dark',
};

export default locale;
