import React from "react";
import TabTwo from "../../elements/tab/TabTwo";

function TheDisruption(props) {
  return (
        <div id="the-disruption" className="fix">
            <div className="about-area ptb--60">
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35">
                            <div className="col-lg-12 area-left">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/brand/atomic-brands.png" alt="About Images"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  	)
}

export default TheDisruption