import React, { Component } from "react";
//import {FaInstagram ,FaFacebookF } from "react-icons/fa";
import { FiX , FiMenu} from "react-icons/fi";
import Scrollspy from 'react-scrollspy';

import LinkMenu from "../../elements/LinkMenu";
import LangMenu from "../../elements/LangMenu";
import Countries from "../../elements/Countries";

/* const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/AtomicKitchens'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
] */
class HeaderThree extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
        
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }

    stickyHeader () {}

    render(){
    	
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                // document.querySelector('.header--fixed').classList.add('sticky')
                document.querySelector('.header-flags').classList.add('hidden')
            }else{
                // document.querySelector('.header--fixed').classList.remove('sticky')
                document.querySelector('.header-flags').classList.remove('hidden')
            }
        });
        

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        // const { logo, color='default-color' } = this.props;
        const { headerClassName, color='default-color' } = this.props;
        let logoUrl;
        logoUrl = <img src="/assets/images/logo/new_Atomic_Kitchens_Logo_Header.png" alt="Atomic_Kitchens" />;
        
        return(
            <header className={`header-area header-style-two header--fixed ${color}  ${headerClassName}`}>
                <div className="header-wrapper">
                    <div className="header-left d-flex align-items-center">
                        <div className="logo">
                            <a href={this.props.homeLink}>
                                {logoUrl}
                            </a>
                        </div>
                        <nav className="mainmenunav d-lg-block ml--50">
                            <Scrollspy className="mainmenu" currentClassName="is-current" offset={-200}>
                                <LinkMenu fromHome="true" />
                            </Scrollspy>
                        </nav>
                    </div>
                    <div className="header-right">
                        {/* Start language Menu in Mobile  */}
                        <div className="content-lang-menu d-block d-lg-none pl--20">
                            <LangMenu></LangMenu>
                        </div>
                        {/* Start Humberger Menu  */}
                        <div className="humberger-menu d-block d-lg-none pl--20">
                            <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                        </div>
                        {/* End Humberger Menu  */}
                        <div className="close-menu d-block d-lg-none">
                            <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                        </div>
                    </div>
                </div>
                <div className="header-wrapper header-flags">
                    <div className="header-center">
                    	<Countries />
                    </div>
                </div>
            </header>
        )
    }
}
export default HeaderThree;