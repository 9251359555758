const locale = {
    ALERT_1: 'No se puede acceder con campos vacíos.',
    ALERT_2: 'Hay un problema con las credenciales que está utilizando para iniciar sesión, comuníquese con Atomic',
    T1: 'El futuro es',
    BEYOND: 'Beyond',
    DARK: 'Dark',
    T2: '¡Activemos cocinas en todo el mundo!',
    T3: '_Invierte con nosotros',
    T4: '“Formamos un equipo multicultural con una gran variedad de afinidades pero con un punto de referencia en común: ayudar a la compañía a crecer y satisfacer las necesidades de nuestros clientes, socios de negocio, accionistas y la comunidad como un todo".',
    I1: 'Correo electrónico',
    I2: 'Contraseña',
    B1: 'Acceder'
};

export default locale;