const BlogContent = [
    {
        images: 'ghost_kitchens',
        alt: 'Euromonitor',
        title: '“Ghost Kitchens a $1trillion global opportunity by 2030”',
        category: 'Euromonitor'
    },
    {
        images: 'effective',
        alt: 'Cost-Effective',
        title: 'Experiment-driven brands and products development paired with low risks is minimizing costs and maximizing profits',
        category: 'Cost-Effective'
    },

    {
        images: 'Latin_America_Canada',
        alt: 'Latin America, the US, and Canada',
        title: 'Exponential growth in Latin America, the US, and Canada through highly-targeted Digital Restaurants',
        category: 'Latin America, the US, and Canada'
    },
]

export default BlogContent;