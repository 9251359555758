import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderLanding from "../component/header/HeaderLanding";
import Footer from "../component/footer/Footer";
import ContactThree from "../elements/contact/ContactThree";
import TheDisruptionLatam from '../component/common/TheDisruptionLatam';
import DisruptionLatam from '../component/common/DisruptionLatam';

const SlideList = [
    {
        textPosition: 'text-left',
        category: '',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]

const PortfolioLanding = () => {
	
    return (
        <div className="active-dark">
            <Helmet pageTitle="Atomic Kitchens" />

            <HeaderLanding headerClassName="sticky" homeLink="/" logo="symbol-dark" color="color-black"/>
            {/* Start Slider Area   */}
            <div id="home" className="fix latam-landing">
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide-primary-header slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 container-rotating-text">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            <h1 className="title span-title">Get NEW 
                                            <TextLoop className="span-title rotating-text">
                                                <span> orders </span>
                                                <span> revenue </span>
                                            </TextLoop>{" "}
                                            </h1>
                                            <h2 className="span-title">in your Kitchen!</h2>
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */} 
            
            {/* The Disruption */}
                <TheDisruptionLatam />
            {/* End The Disruption */}

            {/* Disruption */}
                <DisruptionLatam />
            {/* End Disruption */}

            {/* Start Contact Area */}
            <div id="contact" className="fix">
                <div className="about-area">
                    <div className="about-wrapper" style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
                        <div className="container">
			                <div className="rn-contact-area ptb--60">
			                    <ContactThree contactImages="/assets/images/about/contact_image_atomic.png" contactTitle="Are you ready?" />
			                </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Contact Area */}

            <Footer footerCustomClass="bg_color--5" />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
        </div>
    )
}

export default PortfolioLanding;
