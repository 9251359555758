const locale = {
    TABS_T_1: "Modelo coactivo",
    TABS_D_1: "<p class='h4-description'>En Atomic, partiendo del big-data y la tecnología y utilizando un modelo Co-activo, activamos nuestras marcas a través de las Cocinas de nuestros AtomicPartners.</p><p class='h4-description'>Apoyamos a nuestros AtomicPartners desde la A hasta la Z, haciéndolos más eficientes, rentables y sostenibles, contribuyendo al desarrollo local de las comunidades donde operan, y a la industria global a través de nuestras estrategias coactivas.</p>",
    TABS_T_2: "Rentabiidad",
    TABS_D_2: "<p class='h4-description'>Infraestructura compartida para la producción y distribución por parte de múltiples restaurantes para centrarse únicamente en takeout and delivery.</p><p class='h4-description'>Aumenta tus números mensualmente con sólo un aumento nominal de los gastos generales y reduce tus gastos mediante la compra centralizada y la reducción de las tarifas que pagas a las plataformas.</p><p class='h4-description'>0% de comisión en nuestro propio e-commerce.</p><p class='h4-description'>Ahorra en entrenamiento global, agregadores, asistencia técnica, personal para delivery, empaquetado y/o campañas de marketing.</p>",
    TABS_T_3: "Marcas Digitales",
    TABS_D_3: "<p class='h4-description'>Atomic Kitchens crea conceptos inmejorables y marcas basadas en el valor para los consumidores electrónicos, consiguiendo rápidamente el posicionamiento adecuado tanto en el mercado como en las aplicaciones, con una reputación impecable y altas puntuaciones de satisfacción en los mercados en los que operan.</p><p class='h4-description'>Las marcas y los menús desarrollados por Atomic Kitchens se crean a partir de estudios de mercado y análisis de datos. Son fáciles de implementar y operar, dando a sus socios lo que buscan:<br/><ul class='ul-text'><li>// Maximizar las ventas graduales y mejorar los márgenes con oportunidades de expansión asequibles y de bajo riesgo.</li><li>// Operar marcas rentables y de alto valor para los usuarios finales.</li></ul></p>",
    TABS_T_4: "E-commerce propio",
    TABS_D_4: "<p class='h4-description'>Atomic Kitchens cuenta con su propio e-commerce. Un sistema de pedidos online, eficaz, personalizado y de última generación, diseñado por expertos en experiencia de usuario (UX/UI). Cuenta con alianzas estratégicas de última milla con un soporte excepcional.</p><p class='h4-description'>Páginas web y aplicaciones propias para las Marcas Digitales con la más novedosa tecnología para procesar directamente los pedidos en línea.</p><p class='h4-description'>Un menú universal muy versátil para gestionar fácilmente todos tus menús en línea.</p><p class='h4-description'>Los datos y las herramientas que necesitas para entender realmente tu negocio.</p>",
    TABS_T_5: "Marketing Digital",
    TABS_D_5: "<p class='h4-description'>Desarrollado por The Atomic Lab</p><p class='h4-description'>Estrategias de marketing diseñadas para multi-marcas y productos digitales para llevar.</p><p class='h4-description'>Un equipo de expertos en marketing digital, publicidad, marketing de búsqueda y redes sociales crea estrategias a medida para cada marca y cocinas. Usando tecnología e investigación, también crea contenido creativo, publicidad digital y promociones inteligentes en plataformas y aplicaciones.</p>",
};

export default locale;