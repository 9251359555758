import React from "react";
import TextLoop from "react-text-loop";
// import Countries from "../../elements/Countries";

import i18n from '../../i18n/';
import { useTranslation } from 'react-i18next';
import en from '../../i18n/en/beyondDark';
import fr from '../../i18n/fr/beyondDark';
import es from '../../i18n/es/beyondDark';
i18n.addResourceBundle('en', 'beyondDark', en);
i18n.addResourceBundle('fr', 'beyondDark', fr);
i18n.addResourceBundle('es', 'beyondDark', es);

function BeyonDark(props) {
  const style = {
    backgroundColor: '#ff0066',
    color: '#fff',
    whiteSpace: 'nowrap'
  }
  
  const { t } = useTranslation(['beyondDark']);

  return (
    <div id="beyond-dark" className="fix">
      <div className="about-area ptb--80" style={style}>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center container-area">
              <div className="col-lg-6 area-left">
                <h4 style={{ whiteSpace: 'nowrap' }} className="h4-description title-back-white">{t('D1')}</h4>
                <h4 style={{ whiteSpace: 'nowrap' }} className="h4-description title-back-white">{t('D2')}</h4>
                <div className="d-flex container-rotating-text">
                  <h4 style={{ whiteSpace: 'nowrap' }} className="h4-description title-back-white">{t('D3')}</h4>
                  <TextLoop className="mx-2">
                      <h4 className="h4-description" style={style}> {t('D4')} </h4>
                      <h4 className="h4-description" style={style}> {t('D5')} </h4>
                  </TextLoop>{" "}
                </div>
              </div>
              <div className="col-lg-6 area-right" style={{ whiteSpace: 'initial'}}>
                <h3 className="h3-subtitle">{t('S1')}</h3>
                <div className="d-flex">
                  <h2 className="h2-title" style={{ fontWeight: '400' }}><span style={{ fontWeight: '600' }}>{t('T1')}</span>{t('T2')}</h2>
                  <h4>&#x2122;</h4>
                </div>
                <h4 className="h4-description"> {t('D6')}</h4>
                <h4 className="h4-description">{t('D7')}</h4>  
            {/*
            	<div className="row row-flags ptb--40" style={{ paddingLeft: '15px' }}>
                    <div className="rn-brand-area">
                        <Countries />
                    </div>
                </div>
            */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BeyonDark;