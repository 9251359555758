import React from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import Footer from "../component/footer/Footer";
import TeamDNA from "./TeamDNA";

import i18n from '../i18n/';
import { useTranslation } from 'react-i18next';
import en from '../i18n/en/corporate';
import fr from '../i18n/fr/corporate';
import es from '../i18n/es/corporate';
i18n.addResourceBundle('en', 'corporate', en);
i18n.addResourceBundle('fr', 'corporate', fr);
i18n.addResourceBundle('es', 'corporate', es);

const SlideList = [
  {
    textPosition: "text-left",
    category: "",
    description: "",
    buttonText: "",
    buttonLink: "",
  },
];

const CorporateLanding = () => {

  const { t } = useTranslation(['corporate']);

  const style = {
    backgroundColor: "#ff0066",
    paddingBottom: '0'
  };

  const titleBlock = {
    backgroundColor: "#ff0066",
    paddingBottom: 20,
    paddingTop: 20,
  };

  const positionText = {
    position: "absolute",
    zIndex: 10,
  };

  const team = [

    {
      img: "/assets/images/team/uriel.jpg",
      name: "Uriel Krimer",
      title: t('TEAM_T2'),
      description: t('TEAM_D2'),
    },
    {
      img: "/assets/images/team/cristian_rodriguez_silva.jpg",
      name: "Cristian Rodriguez",
      title: t('TEAM_T4'),
      description: t('TEAM_D4'),
    },
    {
      img: "/assets/images/team/daniel.jpeg",
      name: "Daniel Krimer",
      title: t('TEAM_T3'),
      description: t('TEAM_D3'),
    },
    {
      img: "/assets/images/team/rodolfo_palacio_schneider.jpg",
      name: "Rodolfo (Rudy) Palacio Schneider",
      title: t('TEAM_T1'),
      description: t('TEAM_D1'),
    },
    /*{
      img: "/assets/images/team/yaneth.jpg",
      name: "Yaneth Hernández",
      title: t('TEAM_T6'),
      description: t('TEAM_D6'),
    },*/
    {
      img: "/assets/images/team/ricardo_donoso.jpg",
      name: "Ricardo Donoso",
      title: t('TEAM_T8'),
      description: t('TEAM_D8'),
    },
    {
      img: "/assets/images/team/humberto_fischerl.jpg",
      name: "Humberto Fischer",
      title:  t('TEAM_T9'),
      description:  t('TEAM_D9'),
    },
    /*{
      img: "/assets/images/team/francisco_leitao.jpg",
      name: "Francisco Leitao",
      title: t('TEAM_T10'),
      description: t('TEAM_D10'),
    },*/
  ];

  return (
    <div className="active-dark">
      <Helmet pageTitle="Atomic Kitchens" />

      <HeaderThree
        headerClassName="sticky"
        homeLink="/"
        logo="symbol-dark"
        color="color-black"
      />
      {/* Start Slider Area   */}
      <div id="news" className="fix">
        <div className="slider-wrapper">
          {/* Start Single Slide */}
          {SlideList.map((value, index) => (
            <div
              className="slide slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--31"
              key={index}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 container-rotating-text news">
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ""}
                      <h3
                        className="title span-title"
                        style={{ marginBottom: 20 }}
                      >
                        <span
                          className="title-back-white"
                          style={{ fontWeight: "bolder" }}
                        >
                          {t('T1')}
                        </span>
                      </h3>
                      <h3
                        className="title span-title"
                        style={{ marginBottom: 20 }}
                      >
                        <span className="title-back-white">
                          {t('T2')}
                        </span>
                      </h3>
                      <h3
                        className="title span-title"
                        style={{ marginBottom: 20 }}
                      >
                        <span className="title-back-white">
                          {t('T3_1')} <span style={{ fontWeight: '600' }}>{t('T3_2')}</span>{t('T3_3')}
                        </span>
                      </h3>
                      <br />
                      <h3 className="title span-title last-title">
                        {t('T4_1')}
                        <TextLoop className="span-title rotating-text">
                          <span> {t('T4_2')} </span>
                          <span> {t('T4_3')} </span>
                        </TextLoop>{" "}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* End Single Slide */}
        </div>
      </div>
      {/* End Slider Area   */}

      <div id="who-we-are" className="fix">
        <div className="about-area ptb--40" style={style}>
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center container-area">
                <div className="col-lg-12 area-right">
                  <h2 className="h2-title title" style={{ marginBottom: '20px'}}>{t('T5')}</h2>
                  <h4 className="h4-description" style={{ fontWeight: '400', marginBottom: '20px' }}>
                    {t('D5_1')}
                  </h4>
                  <h4 className="h4-description" style={{ fontWeight: '400', marginBottom: '20px' }}>
                    {t('D5_2')}
                  </h4>
                  <h4 className="h4-description" style={{ fontWeight: '400', marginBottom: '20px' }}>
                    {t('D5_3')}
                  </h4>
                  <h4 className="h4-description" style={{ fontWeight: '400', marginBottom: '20px' }}>
                    {t('D5_4')}
                  </h4>
                  <a href="/#become-atomic-partner">
	                  <h4
	                    className="title-back-white"
	                    style={{ borderRadius: 4, marginTop: 30, padding: '5px 10px', fontSize: '16px' }}
	                  >
	                    {t('T6_1')} <br/> {t('T6_2')}
	                  </h4>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="atomic-culture" className="fix">
        <div className="about-area ptb--40" style={style}>
          <div className="about-wrapper">
            <div className="container culture">
              <div className="row row--35 container-area">
              <div className="col-lg-12 area-top">
                <div className="thumbnail-paralax" style={{ display: 'flex', justifyContent: 'center' }}>
                  <div style={positionText} className="container float-box-bottom">
                    <h3 style={{ fontWeight: '700', paddingRight: '60px' }} className="h3-subtitle title-back-white">{t('S7')}</h3>
                    <h4 style={{ fontWeight: '600', paddingRight: '35px' }} className="h4-description title-back-white">{t('D7')}</h4>
                    <h4 style={{ fontWeight: '600', paddingRight: '110px' }} className="h4-description title-back-white">// {t('D8')}</h4>
                  </div>
                </div>
              	</div>
                <div style={{ padding: '0' }} className="col-lg-12 area-bottom">
                	<div style={{ padding: '100px 15px 30px' }} className="container">
	                  <h4 className="h4-description" style={{ fontWeight: '400', marginBottom: '20px' }}>
	                    {t('D9')}
	                  </h4>
	                  <h4 className="h4-description" style={{ fontWeight: '400', marginBottom: '20px' }}>
                      {t('D10')}
	                  </h4>
	                  <h4 className="h4-description" style={{ fontWeight: '400', marginBottom: '20px' }}>
                      {t('D11')}
	                  </h4>
	                  <h4 className="h4-description" style={{ fontWeight: '400', marginBottom: '20px', fontStyle: "italic" }}>
                     {t('D12')}
	                  </h4>
	                  <h4 className="h4-description" style={{ fontWeight: '400', marginBottom: '20px' }}>
                     {t('D13')}
	                  </h4>
	                  <h4 className="h4-description h4-list" style={{ fontWeight: '400' }}>// {t('D14')}</h4>
	                  <h4 className="h4-description h4-list" style={{ fontWeight: '400' }}>// {t('D15')}</h4>
	                  <h4 className="h4-description" style={{ fontWeight: '400', marginBottom: '20px' }}>// {t('D16')}</h4>
	                  <h4 className="h4-description" style={{ fontWeight: '400' }}>
                      {t('D17')}
	                  </h4>
	                  <br />
                	</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Start Atomic\'s DNA & Management Team */}
      <main id="dna" className="page-wrapper page-team">
        <div style={{ padding: '40px 0' }} className="bg_color--5 section-adn">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="single-column">
                  <div className="section-title">
                    <br />
                    <h2 style={{ fontSize: '48px', display:'grid' }} className="title">{t('T6')}</h2>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 content-text">
                <div className="single-column single-intro" style={{ marginBottom: '20px' }}>
                  <p className="h4-description" style={{ fontSize: '20px', fontWeight: '400', color: '#fff' }}>
                    {t('D18')}
                  </p>
                </div>
                <div className="single-column single-intro" style={{ marginBottom: '20px' }}>
                  <p className="h4-description" style={{ fontSize: '20px', fontWeight: '400', color: '#fff' }}>
                    {t('D19')}
                  </p>
                </div>
                <div className="single-column single-intro" style={{ marginBottom: '20px' }}>
                  <p className="h4-description" style={{ fontSize: '20px', fontWeight: '400', color: '#fff' }}>
                    {t('D20_1')}{" "}
                    <span style={{ color: "#ff0066" }}>{t('D20_2')}</span>{" "}
                    {t('D20_3')} <span style={{ fontWeight: '600' }}>Beyond</span>Dark.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Atomic\'s DNA & Management Team */}

        {/* Start Columns Area  */}
        <div className="rn-columns-area bg_color--5 section-profiles">
          <div className="container">
            <div className="row">
              {team.map((t, key) => (
                <TeamDNA key={key} team={t} />
              ))}
            </div>
          </div>
        </div>
      </main>
      {/* Start Columns Area  */}

      <Footer footerCustomClass="bg_color--5" />
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  );
};

export default CorporateLanding;
