const locale = {
    ALERT_1: 'Cannot access with empty fields',
    ALERT_2: 'There\'s a problem with the credentials you\'re using to sign in, please contact Atomic',
    T1: 'The future is',
    BEYOND: 'Beyond',
    DARK: 'Dark',
    T2: 'Let’s activate kitchens worldwide!',
    T3: '_investor relations',
    T4: '“We form a multicultural team with a wide variety of affinities but with one common reference point: helping the company to grow and meeting the needs of our customers, business partners, shareholders, and society as a whole".',
    I1: 'E-mail',
    I2: 'Password',
    B1: 'Login'
};

export default locale;