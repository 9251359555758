import React, { Component } from "react";
//import {FaInstagram ,FaFacebookF } from "react-icons/fa";
import { FiX , FiMenu} from "react-icons/fi";
import Scrollspy from 'react-scrollspy';
import LinkMenu from "../../elements/LinkMenu";
import Countries from "../../elements/Countries";

/* const SocialShare = [
    {Social: <FaFacebookF /> , link: 'https://www.facebook.com/AtomicKitchens'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
] */
class HeaderThree extends Component{
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

       //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function() {
            console.log('All assets are loaded');
        })
        
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }

    stickyHeader () {}

    render(){
    	/*
        window.addEventListener('scroll', function() {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            }else{
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });
        */

        var elements = document.querySelectorAll('.has-droupdown > a');
        for(var i in elements) {
            if(elements.hasOwnProperty(i)) {
                elements[i].onclick = function() {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        // const { logo, color='default-color' } = this.props;
        const { headerClassName, color='default-color' } = this.props;
        let logoUrl;
        logoUrl = <img src="/assets/images/logo/new_Atomic_Kitchens_Logo_Header.png" alt="Atomic_Kitchens" />;
        
        return(
            <header className={`header-lading header-area header-style-two header--fixed ${color}  ${headerClassName}`}>
                <div className="header-wrapper">
                    <div className="header-left d-flex align-items-center">
                        <div className="logo">
                            <a>
                                {logoUrl}
                            </a>
                        </div>
                        <nav className="mainmenunav d-lg-block ml--50" style={{ padding: '32px 0' }}>
                            <Scrollspy className="mainmenu" currentClassName="is-current" offset={-200}>
                                
                            </Scrollspy>
                        </nav>
                    </div>
                    <div className="header-right header-flags" style={{ paddingTop: '30px' }}>
                    	<Countries />
                    </div>
                </div>
                <div className="header-wrapper header-flags">
                    <div className="header-center">
                    	<Countries />
                    </div>
                </div>
            </header>
        )
    }
}
export default HeaderThree;