import React from "react";

import i18n from '../../i18n/';
import { useTranslation } from 'react-i18next';
import en from '../../i18n/en/extraIncome';
import fr from '../../i18n/fr/extraIncome';
import es from '../../i18n/es/extraIncome';
i18n.addResourceBundle('en', 'extraIncome', en);
i18n.addResourceBundle('fr', 'extraIncome', fr);
i18n.addResourceBundle('es', 'extraIncome', es);

function ExtraIncome(props) {

  const { t } = useTranslation(['extraIncome']);

  return (
    <div id="extra-income" className="fix">
      <div className="about-area">
        <div className="about-wraper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6">
                <div className="thumbnail-paralax">
                  <div className="float-box">
                    <h3 className="h3-subtitle" style={{ fontWeight: '700' }}>{t('S1')}</h3>
                    <h4 className="h4-description" style={{ fontWeight: '400' }}>{t('D1')}</h4>
                    <h4 className="h4-description" style={{ fontWeight: '400' }}>{t('D2')}</h4>
                    <h4 className="h4-description" style={{ fontWeight: '400' }}>{t('D3')}</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-4 area-right">
                <h3 className="h3-subtitle" style={{ fontWeight: '700', marginBottom: '10px' }}>
                  <span style={{ textDecoration: 'underline', margin: 'auto .25rem' }}>
                    {t('S2')}
                  </span>
                </h3>

                <ul className="my-2 underscore list-section" style={{ color: '#fff' }}>
                  <li className="adjust">
                    <h4 style={{ fontWeight: '400' }} className="h4-description mx-1 my-1">{t('D5')}</h4>
                  </li>
                  <li className="adjust">
                    <h4 style={{ fontWeight: '400' }} className="h4-description mx-1 my-1">{t('D6')}</h4>
                  </li>
                  <li className="adjust">
                    <h4 style={{ fontWeight: '400' }} className="h4-description mx-1 my-1">{t('D8')}</h4>
                  </li>
                  <li className="adjust">
                    <h4 style={{ fontWeight: '400' }} className="h4-description mx-1 my-1">{t('D9')}</h4>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExtraIncome