import React, { Component } from "react";

class Countries extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="brand-style-2">
                    <div className="" style={{ margin: 'auto', justifyContent: 'left', padding: '15px 0px 20px 0px', color: 'white' }}>
                        {/*<div className="col-flag col-xs-1 mr--10 text-center">
                            <img src="/assets/images/countries/ca.jpg" alt="canada"/>
                        </div> 
                        <div className="col-flag col-xs-1 mr--10 text-center" >
                            <img src="/assets/images/countries/cl.jpg" alt="chile"/>
                        </div>   
                        <div className="col-flag col-xs-1 mr--10 text-center" >
                            <img src="/assets/images/countries/ar.jpg" alt="argentina"/>
                        </div> 
                        <div className="col-flag col-xs-1 mr--10 text-center" >
                            <img src="/assets/images/countries/mx.jpg" alt="mexico"/>
                        </div> */}
                        <div className="power-kitchens" style={{fontFamily: 'helvetica', fontSize: '15px'}}>
                            <h6 style={{ display: 'inline-block', marginRight: '4px' }}>Powering Kitchens in:</h6>
                            <span style={{ display: 'inline-block' }}>Chile//Argentina//Uruguay//Bolivia//</span>
                            <span style={{ display: 'inline-block' }}>Panama//R.Dominicana//Canada//</span>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Countries;