import React, { Component } from "react";
import { FaMapMarkerAlt } from 'react-icons/fa'

import i18n from '../../i18n/';
import { withTranslation } from 'react-i18next';
import en from '../../i18n/en/contactThree';
import fr from '../../i18n/fr/contactThree';
import es from '../../i18n/es/contactThree';
i18n.addResourceBundle('en', 'contactThree', en);
i18n.addResourceBundle('fr', 'contactThree', fr);
i18n.addResourceBundle('es', 'contactThree', es);
class ContactThree extends Component{
    constructor(props){
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
            classPhar: {
                color: '#fff',
            }
        };
    }
    handleSubmit(event) {
        if (this.state.rnName && this.state.rnEmail && this.state.rnSubject && this.state.rnMessage) {
            this.sendEmail()

            this.setState({
                rnName: '',
                rnEmail: '',
                rnSubject: '',
                rnMessage: '',
            })

            alert(this.props.t('ALERT_1'));
        }
        else {
            alert(this.props.t('ALERT_2'));
        }
        event.preventDefault();
    }
    sendEmail() {
        fetch('https://tastymailsca.com/api/atomic', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiZDAzOGY4MzBlY2JlMTMwMjM5MGIwMWJmM2MyMTlhNjViN2NkNjU5ZWQ5MGFlNGNlMTk5NzE5ODJhZTVkMmU0NjQzZGQ1YzYzZjBkNGVmMmMiLCJpYXQiOjE2NDk4OTQxMzgsIm5iZiI6MTY0OTg5NDEzOCwiZXhwIjoxNjgxNDMwMTM4LCJzdWIiOiIiLCJzY29wZXMiOlsiKiJdfQ.MTbVUqbO3a-Xh1PGYigfiIBffogVi6avn3XU5u__oViBs4HshDU9LkW5Z3cvHloVIJWbH2ZuPY_vHUQE4mCIQo_0Ig5_Id3vBd3fCvKX5EDIk5ayABedbMiklByG654wGL02mZFoQ2aovQYAoo-28j7rcq2AIMRKEYK5_drX-o9eloIrP0ZQZLs8Tcuhu4STomABKOqOIzAANe5mqmHUoMB_0Pt03yY4vDqTT3ircK4o-v85PuDCdtOY0n9HAyvi3PKsjhMH3XDvAcaeReQ0ZlblMlAKxvS0F8FfVzF_p4PhB7A-fFta9FpOtf1s45G9hi0cUj-1AyH0B1vYja_4rd3aM-n-e1g3K1bN9VKY7i_LF6tSdwb8QPsCLFZvZ-fxMVDfa6q4U33FYLs30mnUZxxmVhmke-kZ76klGmFktnAMMIdvthq5w1RzNseCYYtC7HG6Aa4ZWQaIulLS2IAQ8l67L3iqR0e46DKwacqnLoR_BS2sNC_jOipeYrhch4k2bF6UGQ4zFH_4HDPpEpRSibQ4Ep1eXLtikRAhwBxjn-Jr70jwT0qB86pRB7KiInWmYBntGROYN5MwBtTdoMXf8VOBZ5qim4ABo_WSDZ_xOv2cKEqMCQn6hDR49wZySoXH9jgkF3MPu17qvJwXQ1Y6qbsO_nmlfHJYeVwDisjNz14',
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                name: this.state.rnName,
                email: this.state.rnEmail,
                subject: this.state.rnSubject,
                message: this.state.rnMessage
            })
        })
            .then(res => res.json())
            .then(res => console.log(res))
            .catch(err => console.error(err))
    }
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-center">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="box-text text-left mb--30">
                            </div>
                            <div className="box-form form-wrapper">
	                            <div className="text-left mb--30">
	                                <p className="h4-description" style={this.state.classPhar}>{this.props.t('D1')}</p>
	                                <p className="h4-description" style={this.state.classPhar}>{this.props.t('D2')}</p>
	                            </div>
	                            <div className="text-left mb--30">
	                                <h2 className="h2-title title" style={{ width: 'max-content', marginTop: '0' }}>{this.props.contactTitle}</h2>
	                                <h2 className="h2-title title" style={{ width: 'max-content' }}>{this.props.t('T1')}</h2>
	                                <h3 className="h3-subtitle title" style={{ marginBottom: '40px' }}>{this.props.t('S1')} <span style={{ fontWeight: '600' }}>{this.props.t('BEYOND')}</span><span style={{ fontWeight: '400' }}>{this.props.t('DARK')}</span></h3>
	                            </div>
                                <form onSubmit={this.handleSubmit.bind(this)}>
                                    <label htmlFor="item01" style={{ background: '#101010' }}>
                                        <input
                                            type="text"
                                            name="name"
                                            id="item01"
                                            value={this.state.rnName}
                                            onChange={(e)=>{this.setState({rnName: e.target.value});}}
                                            placeholder={this.props.t('FORM_NAME')}
                                        />
                                    </label>

                                    <label htmlFor="item02" style={{ background: '#101010' }}>
                                        <input
                                            type="email"
                                            name="email"
                                            id="item02"
                                            value={this.state.rnEmail}
                                            onChange={(e)=>{this.setState({rnEmail: e.target.value});}}
                                            placeholder={this.props.t('FORM_EMAIL')}
                                        />
                                    </label>

                                    <label htmlFor="item03" style={{ background: '#101010' }}>
                                        <input
                                            type="text"
                                            name="subject"
                                            id="item03"
                                            value={this.state.rnSubject}
                                            onChange={(e)=>{this.setState({rnSubject: e.target.value});}}
                                            placeholder={this.props.t('FORM_SUBJECT')}
                                        />
                                    </label>
                                    <label htmlFor="item04" style={{ background: '#101010' }}>
                                        <textarea
                                            type="text"
                                            id="item04"
                                            name="message"
                                            value={this.state.rnMessage}
                                            onChange={(e)=>{this.setState({rnMessage: e.target.value});}}
                                            placeholder={this.props.t('FORM_MESSAGE')}
                                        />
                                    </label>
                                    <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">{this.props.t('B_SUBMIT')}</button>
                                </form>
		                        <div className="section-head-office">
		                            <p>
		                            	<h3 className="h3-subtitle title">Atomic Kitchens</h3>
		                            	<em>
		          							<FaMapMarkerAlt style={{ marginBottom: '4px', marginRight: '4px' }} /> 
		          							<a href="https://www.google.com/maps/search/1010+848+Brickell+Avenue+Miami,+FL,+33131/@25.7664699,-80.1903029,17z/data=!3m1!4b1?hl=es" target="_blank">1010 848 Brickell Avenue Miami, FL, 33131.</a>
		          						</em>
		          					</p>
		                        </div>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src={`${this.props.contactImages}`} alt="trydo"/>
                            </div>
	                        <div className="row section-head-office">
	                            <p>
	                            	<h3 className="h3-subtitle title">Atomic Kitchens</h3>
	                            	<em>
	          							<FaMapMarkerAlt style={{ marginBottom: '4px', marginRight: '4px' }} /> 
	          							<a href="https://www.google.com/maps/search/1010+848+Brickell+Avenue+Miami,+FL,+33131/@25.7664699,-80.1903029,17z/data=!3m1!4b1?hl=es" target="_blank">1010 848 Brickell Avenue Miami, FL, 33131.</a>
	          						</em>
	          					</p>
	                        </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation(['contactThree'])(ContactThree);