const locale = {
    T1_1: 'Let\'s do it together...',
    T1_2: 'Beyond',
    T1_3: 'Dark.',
    T2: 'Atomic Kitchens Co-Active Model',
    T3: 'The Atomic take cares of:',
    T4: 'Brand management and marketing',
    T5: 'DeliveryApps positioning and brand reputation',
    T6: 'Orders pickup',
    T7: 'Ultra efficient food costing & operation',
};

export default locale;
